import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { BaseApi } from '../core/base-api';
import { UrlService } from '../../services/url.service';
import { TokenResponse } from '../../models/token-response.model';
import { PrivateModeService } from './private-mode.service';
import { AuthParams } from '../../models/auth-params.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { GuidService } from './guid.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ClearStoreService } from './clear-store.service';
import { ClientStore } from '../storage/client.store';
import { AffiliateService } from './affiliate.service';
import { EnvironmentType } from '../../models/environment.type';
import { WINDOW } from '../injections/window';
import Fingerprint2 from 'fingerprintjs2';
import { SendEventHelper } from '../../helpers/send-event.helper';

@Injectable()
export class AuthenticationService extends BaseApi {
  public readonly clientLoggedIn;
  readonly TOKEN = 'jwt_token';
  readonly REFRESH_TOKEN = 'refresh_token';
  readonly IS_AUTHENTICATED = 'isAuthenticated';
  readonly USER_NAME = 'user';
  readonly GA_COOKIE = '_ga';
  public ENV = this.environment;
  readonly GA_COOKIE_TAG = '_ga_' + this.ENV.GA_TAG;
  private storage: Storage = sessionStorage;
  private isPrivate: boolean;
  private utmParams;
  private referrer;
  private scope: string;
  private platform: string;
  private fingerprintId!: string;

  constructor(
    @Inject('environment') private environment: EnvironmentType,
    @Inject(WINDOW) private readonly window: Window,
    public http: HttpClient,
    private urlService: UrlService,
    private privateModeService: PrivateModeService,
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService,
    private guidService: GuidService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clearStoreService: ClearStoreService,
    private clientStore: ClientStore,
    private affiliateService: AffiliateService,
    private eventHelper: SendEventHelper,
  ) {
    super(http);
    this.activateIsPrivate();
    this.clientLoggedIn = new BehaviorSubject(this.isAuthenticated());
    Fingerprint2.getV18((result: string) => (this.fingerprintId = result));
    this.scope = this.ENV.name;
  }

  public getPlatform(): void {
    this.platform = Capacitor.getPlatform();
  }

  public loginUserByVerificationToken(token: any, username?: string): Observable<TokenResponse> {
    this.eventHelper.send(username, 'init /auth?verification');
    this.getPlatform();
    let tokenObj;
    if (typeof token !== 'string') {
      tokenObj = JSON.parse(token.data.toString());
    }
    this.eventHelper.send(username, `tokenObj  ${tokenObj ? tokenObj.token : token}`);

    const verificationToken = typeof token === 'string' ? token : tokenObj.token;
    this.eventHelper.send(username, `verificationToken  ${verificationToken}`);
    this.eventHelper.send(username, 'before quryparams /auth?verification');
    this.setQueryParams();
    const body: User = {
      username,
      verificationToken: verificationToken,
      scope: this.scope,
      authParams: this.getAuthParams(),
    };

    const url = `${this.urlService.loginUrl}?verification`;
    const options = {
      withCredentials: true,
    };
    this.eventHelper.send(username, 'started /auth?verification');
    return this.http.post<TokenResponse>(url, body, options);
  }

  public getAuthParams(): AuthParams {
    this.affiliateService.setAffiliate();
    return {
      privateMode: this.isPrivate,
      userAgent: this.deviceService.getDeviceInfo().userAgent,
      guid: this.guidService.getGuid(),
      ga: this.cookieService.get(this.GA_COOKIE),
      gaTag: this.cookieService.get(this.GA_COOKIE_TAG),
      affiliate: this.affiliateService.getAffiliate(),
      fingerprint: {
        id: this.fingerprintId,
      },
      utm: {
        content: this.utmParams.utm_content,
        source: this.utmParams.utm_source,
        medium: this.utmParams.utm_medium,
        campaign: this.utmParams.utm_campaign,
        term: this.cookieService.get('utm_term') ?? null,
        referrer: this.referrer ? this.referrer : this.utmParams.referrer,
        fullLink: window.location.href,
        gclid: this.cookieService.get('gclid') ?? null,
        department: this.cookieService.get('department') ?? null,
      },
    };
  }

  public setQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.ref) {
        this.referrer = params.ref;
      }
      switch (true) {
        case (params.utm_content === undefined &&
          params.utm_source === undefined &&
          params.utm_medium === undefined &&
          params.utm_campaign === undefined) ||
          (params.utm_content === null && params.utm_source === null && params.utm_medium === null && params.utm_campaign === null):
          switch (true) {
            case document.referrer === '':
              this.utmParams = {
                utm_content: this.cookieService.get('utm_content') ?? null,
                utm_source: this.cookieService.get('utm_source') ?? null,
                utm_medium: this.cookieService.get('utm_medium') ?? null,
                utm_campaign: this.cookieService.get('utm_campaign') ?? null,
              };
              break;
            case document.referrer === 'https://www.google.com/':
            case document.referrer === 'https://www.google.com.ua/':
              this.utmParams = {
                utm_content: null,
                utm_source: 'google.com',
                utm_medium: 'organic',
                utm_campaign: null,
              };
              break;
            case document.referrer !== 'https://www.google.com/':
            case document.referrer !== 'https://www.google.com.ua/':
              this.utmParams = {
                utm_content: this.cookieService.get('utm_content') ?? null,
                utm_source: this.cookieService.get('utm_source') ?? document.referrer,
                utm_medium: this.cookieService.get('utm_medium') ?? 'referral',
                utm_campaign: this.cookieService.get('utm_campaign') ?? null,
              };
              break;
          }
          break;
        default:
          this.utmParams = params;
          break;
      }
    });
  }

  public getToken(): string {
    return this.clientStore.getClientToken();
  }

  public refreshToken(): Observable<TokenResponse> {
    const options = {
      withCredentials: true,
    };
    return this.http.post<TokenResponse>(this.urlService.refreshUrl, {}, options);
  }

  public setToken(tokenResp: TokenResponse): void {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'setToken method');
    this.clientStore.setClientToken(tokenResp.token);
    this.eventHelper.send(sessionStorage.getItem('phone'), 'after setClientToken method');
    this.cookieService.set(this.IS_AUTHENTICATED, 'true', null, '/');
    this.setLoggedIn(true);
    this.eventHelper.send(sessionStorage.getItem('phone'), 'after setLoggedIn method');
    this.addEventToDataLayer();
    this.eventHelper.send(sessionStorage.getItem('phone'), 'after addEventToDataLayer method');
  }

  public isLoggedIn(): Observable<boolean> {
    return this.clientLoggedIn.asObservable();
  }

  public setLoggedIn(c: boolean): void {
    this.clientLoggedIn.next(c);
  }

  public isAuthenticated(): boolean {
    return Boolean(this.cookieService.get(this.IS_AUTHENTICATED));
  }

  public logout(): void {
    this.http.get<any>(this.urlService.logoutUrl, { withCredentials: true }).subscribe(() => {
      this.clearStoreService.clear();
      this.setLoggedIn(false);
      this.storage.removeItem(this.TOKEN);
      this.storage.removeItem(this.REFRESH_TOKEN);
      this.cookieService.delete(this.IS_AUTHENTICATED, '/');
      sessionStorage.clear();
      this.clientStore.clear();
      this.router.navigate(['auth', 'login']);
    });
  }

  private activateIsPrivate(): void {
    this.privateModeService.isPrivateMode().then((isPrivate: boolean) => {
      this.isPrivate = isPrivate;
    });
  }

  private addEventToDataLayer(): void {
    this.window['dataLayer'].push({
      event: 'auth_funnel_phone_success',
      funnel_step: 'Step 1',
      phone: `${window.sessionStorage.getItem('phone')}`,
    });
  }
}
