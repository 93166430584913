<section [formGroup]="form" class="calculate">
  <div class="calculate-wrap">
    <div class="range">
      <div class="range-header d-flex align-items-center justify-content-between">
        <span class="range-label fw-bold">{{ 'CALCULATE.AMOUNT' | translate }}</span>
        <span class="range-output fw-semi-bold text-right">{{ currentAmount }} грн</span>
      </div>
      <div class="range-buttons-wrap">
        <span (click)="updateAmount(currentAmount - 100)" class="range-button range-button_increment">—</span>
        <span (click)="updateAmount(currentAmount + 100)" class="range-button range-button_decrement">+</span>
      </div>
      <div class="range-control">
        <mat-slider
          (input)="updateAmount($event.value)"
          [max]="product?.maxAmount"
          [min]="product?.minAmount"
          [step]="100"
          [value]="currentAmount"
          formControlName="amount"
        ></mat-slider>
      </div>
      <div class="range-footer d-flex justify-content-between">
        <span>{{ 'CALCULATE.AMOUNT_FROM' | translate : { value: product?.minAmount } }}</span>
        <span>{{ 'CALCULATE.AMOUNT_TILL' | translate : { value: product?.maxAmount } }}</span>
      </div>
    </div>
    <div class="range">
      <div class="range-header d-flex align-items-center justify-content-between">
        <span class="range-label fw-bold">{{ 'CALCULATE.TERM' | translate }}</span>
        <span class="range-output fw-semi-bold text-right">{{ currentTerm }} {{ 'CALCULATE.DAYS' | translate }}</span>
      </div>
      <div class="range-buttons-wrap">
        <span (click)="updateTerm(currentTerm - 5)" class="range-button range-button_increment">—</span>
        <span (click)="updateTerm(currentTerm + 5)" class="range-button range-button_decrement">+</span>
      </div>
      <div class="range-control">
        <mat-slider
          (input)="updateTerm($event.value)"
          [max]="product?.maxTerm"
          [min]="product?.minTerm"
          [step]="product?.termStep"
          [value]="currentTerm"
          formControlName="term"
        ></mat-slider>
      </div>
      <div class="range-footer d-flex justify-content-between">
        <span>{{ 'CALCULATE.TERM_FROM' | translate : { value: product?.minTerm } }}</span>
        <span>{{ 'CALCULATE.TERM_TILL' | translate : { value: product?.maxTerm } }}</span>
      </div>
    </div>
  </div>
</section>
