import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '../../../../shared/components/checkbox/checkbox.module';
import { AuthAgreementComponent } from './auth-agreement.component';

@NgModule({
  declarations: [AuthAgreementComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, CheckboxModule],
  exports: [AuthAgreementComponent],
})
export class AuthAgreementModule {}
