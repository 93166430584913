<div [class]="classes" class="countdown">
  <div class="countdown-header d-flex justify-content-center">
    <span *ngIf="verificationType === 'SMS'" class="countdown-label countdown-label_sms mr-1">
      {{ 'CONFIRM.CODE_WAS_SEND' | translate }}
    </span>
    <span *ngIf="verificationType === 'PHONE' || verificationType === 'IVR'" class="countdown-label countdown-label_phone mr-1">
      {{ 'CONFIRM.WAIT_CALL' | translate }}
    </span>
    <app-counter-widget
      (breakpointOutEvent)="breakpointOutEvent.emit($event)"
      (tickEvent)="tickEventEventHandler($event)"
      (timeOutEvent)="timeOutEvent.emit($event)"
      [group]="group"
      [initialState]="initialState"
      [type]="counterType"
    ></app-counter-widget>
  </div>
  <div *ngIf="showProgressBar" class="countdown-progress mt-3">
    <div [style.width]="progressValue" class="countdown-progress-line"></div>
  </div>
</div>
