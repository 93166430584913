import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ILoanProduct } from '../../../../models/models';
import { ToasterService } from '../../toaster-widget/toaster.service';
import { GroupType } from '../../../../types/group.type';
import { environment } from '../../../../environment';
import { conditionalOperator } from '../../../operators/conditional.operator';

@Component({
  selector: 'app-calculate-widget',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: [`./templates/${environment.group}/style.sass`],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculateWidgetComponent implements OnInit {
  @Input()
  public product: ILoanProduct;
  @Input()
  public isDisabledPromo: boolean;
  @Input()
  public group: GroupType;
  @Output()
  public readonly calculateChangeEvent: EventEmitter<{
    amount: number;
    term: number;
    interest: number;
  }> = new EventEmitter<{
    amount: number;
    term: number;
    interest: number;
  }>();
  public form: FormGroup;
  public currentAmount: number;
  public currentTerm: number;
  @Input()
  private formGroupName: string;
  private amountSubject = new BehaviorSubject<number>(0);

  constructor(private rootFormGroup: FormGroupDirective, private toaster: ToasterService) {}

  @Input()
  public set term(term: number) {
    this.currentTerm = term;
  }

  @Input()
  public set amount(amount: number) {
    this.currentAmount = amount;
  }

  public ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.amountMassageControl();
    this.updateTerm(this.currentTerm);
    this.updateAmount(this.currentAmount);
  }

  public updateAmount(value: number): void {
    this.amountSubject.next(value);
    this.currentAmount =
      value > this.product.maxAmount ? this.product.maxAmount : value < this.product.minAmount ? this.product.minAmount : value;

    sessionStorage.setItem('amount', JSON.stringify(this.currentAmount));
    this.form.get('amount').setValue(this.currentAmount);
    this.calculateChangeEvent.emit({
      amount: this.currentAmount,
      term: this.currentTerm,
      interest: this.product.interest,
    });
  }

  public updateTerm(value: number): void {
    this.currentTerm = value > this.product.maxTerm ? this.product.maxTerm : value < this.product.minTerm ? this.product.minTerm : value;

    sessionStorage.setItem('term', JSON.stringify(this.currentTerm));
    this.form.get('term').setValue(this.currentTerm);
    this.calculateChangeEvent.emit({
      amount: this.currentAmount,
      term: this.currentTerm,
      interest: this.product.interest,
    });
  }

  private amountMassageControl(): void {
    this.amountSubject
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        conditionalOperator({
          condition: (value: number) => value === this.product?.maxAmount,
          trueCase: () => this.toaster.warning('CALCULATE.MAX_AMOUNT_MESSAGE'),
        }),
        take(1),
      )
      .subscribe();
  }
}
