import { InjectionToken } from '@angular/core';

export interface INotice {
  title?: string;
  info?: string;
  status: string;
  img: {
    url: string;
    height?: string;
    width?: string;
  };
}

export interface LoaNotices {
  [key: string]: INotice;
}

export const NOTICE_AVANS: LoaNotices = {
  APPROVED: {
    title: 'STATEMENT.APPROVED.TITLE',
    info: 'STATEMENT.APPROVED.INFO',
    status: 'APPROVED',
    img: {
      url: '/assets/images/icons/success.webp',
      width: '26px',
      height: '26px',
    },
  },
  PROMO_APPLIED: {
    title: 'STATEMENT.APPROVED.TITLE',
    info: 'STATEMENT.APPROVED.INFO',
    status: 'PROMO_APPLIED',
    img: {
      url: '/assets/images/icons/success.webp',
      width: '26px',
      height: '26px',
    },
  },
  APPLY_PROMO: {
    title: 'STATEMENT.APPLY_PROMO.TITLE',
    status: 'APPLY_PROMO',
    img: {
      url: '/assets/images/icons/percent.svg',
      width: '26px',
      height: '26px',
    },
  },
  ACTIVE: {
    title: 'STATEMENT.ACTIVE.TITLE',
    info: 'STATEMENT.ACTIVE.INFO',
    status: 'ACTIVE',
    img: {
      url: '/assets/images/icons/success.webp',
      width: '26px',
      height: '26px',
    },
  },
  FREEZE: {
    title: 'STATEMENT.FREEZE.TITLE',
    info: 'STATEMENT.FREEZE.INFO',
    status: 'FREEZE',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  RESTRUCTURED: {
    title: 'STATEMENT.RESTRUCTURED.TITLE',
    info: 'STATEMENT.RESTRUCTURED.INFO',
    status: 'RESTRUCTURED',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  RESTRUCTURING_SIGN: {
    title: 'STATEMENT.RESTRUCTURING_SIGN.TITLE',
    info: 'STATEMENT.RESTRUCTURING_SIGN.INFO',
    status: 'RESTRUCTURING_SIGN',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  EXPIRED: {
    title: 'STATEMENT.EXPIRED.TITLE',
    info: 'STATEMENT.EXPIRED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  PENALTY: {
    title: '',
    info: 'STATEMENT.PENALTY.INFO',
    status: 'PENALTY',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  CALCULATION_STOPPED: {
    title: 'STATEMENT.CALCULATION_STOPPED.TITLE',
    info: 'STATEMENT.CALCULATION_STOPPED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  WILL_EXPIRED: {
    title: 'STATEMENT.WILL_EXPIRED.TITLE',
    info: 'STATEMENT.WILL_EXPIRED.INFO',
    status: 'WILL_EXPIRED',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  ACTION: {
    title: 'STATEMENT.ACTION.TITLE',
    info: 'STATEMENT.ACTION.INFO',
    status: 'ACTION',
    img: {
      url: '/assets/images/icons/action.webp',
      width: '28px',
      height: '28px',
    },
  },
  OUTSOURCE: {
    title: 'STATEMENT.OUTSOURCE.TITLE',
    info: 'STATEMENT.OUTSOURCE.INFO',
    status: 'OUTSOURCE',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  SOLD: {
    title: 'STATEMENT.SOLD.TITLE',
    info: 'STATEMENT.SOLD.INFO',
    status: 'SOLD',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  PROLONGATION: {
    title: 'STATEMENT.PROLONGATION.TITLE',
    info: 'STATEMENT.PROLONGATION.INFO',
    status: 'PROLONGATION',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  ERROR_LOGIN: {
    title: '',
    info: 'STATEMENT.ERROR_LOGIN.INFO',
    status: 'ERROR_LOGIN',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
  UNKNOWN: {
    title: 'UNKNOWN',
    info: 'UNKNOWN',
    status: 'UNKNOWN',
    img: {
      url: '/assets/images/icons/attention.webp',
      width: '26px',
      height: '26px',
    },
  },
};

export const NOTICES_STARFIN: LoaNotices = {
  APPROVED: {
    title: 'STATEMENT.APPROVED.TITLE',
    status: 'APPROVED',
    img: {
      url: '/assets/images/icons/icon-approved.png',
      width: '24px',
      height: '20px',
    },
  },
  ACTIVE: {
    title: 'STATEMENT.ACTIVE.TITLE',
    info: 'STATEMENT.ACTIVE.INFO',
    status: 'ACTIVE',
    img: {
      url: '/assets/images/icons/icon-success.png',
      width: '22px',
      height: '18px',
    },
  },
  FREEZE: {
    title: 'STATEMENT.FREEZE.TITLE',
    info: 'STATEMENT.FREEZE.INFO',
    status: 'FREEZE',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '24px',
    },
  },
  RESTRUCTURED: {
    title: 'STATEMENT.RESTRUCTURED.TITLE',
    info: 'STATEMENT.RESTRUCTURED.INFO',
    status: 'RESTRUCTURED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '24px',
    },
  },
  RESTRUCTURING_SIGN: {
    title: 'STATEMENT.RESTRUCTURING_SIGN.TITLE',
    info: 'STATEMENT.RESTRUCTURING_SIGN.INFO',
    status: 'RESTRUCTURING_SIGN',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '24px',
    },
  },
  EXPIRED: {
    title: 'STATEMENT.EXPIRED.TITLE',
    info: 'STATEMENT.EXPIRED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-expired.png',
      width: '18px',
      height: '18px',
    },
  },
  PENALTY: {
    title: 'STATEMENT.EXPIRED.TITLE',
    info: 'STATEMENT.EXPIRED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-expired.png',
      width: '18px',
      height: '18px',
    },
  },
  CALCULATION_STOPPED: {
    title: 'STATEMENT.CALCULATION_STOPPED.TITLE',
    info: 'STATEMENT.CALCULATION_STOPPED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '24px',
    },
  },
  TWO_DAYS_LEFT: {
    title: 'STATEMENT.TWO_DAYS_LEFT.TITLE',
    status: 'TWO_DAYS_LEFT',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '26px',
      height: '26px',
    },
  },
  WILL_EXPIRED: {
    title: 'STATEMENT.WILL_EXPIRED.TITLE',
    status: 'WILL_EXPIRED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '24px',
    },
  },
  ACTION: {
    title: 'STATEMENT.ACTION.TITLE',
    info: 'STATEMENT.ACTION.INFO',
    status: 'ACTION',
    img: {
      url: '/assets/images/icons/icon-action.png',
      width: '24px',
      height: '24px',
    },
  },
  OUTSOURCE: {
    title: 'STATEMENT.OUTSOURCE.TITLE',
    info: 'STATEMENT.OUTSOURCE.INFO',
    status: 'OUTSOURCE',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '22px',
    },
  },
  SOLD: {
    title: 'STATEMENT.SOLD.TITLE',
    info: 'STATEMENT.SOLD.INFO',
    status: 'SOLD',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '22px',
    },
  },
  PROLONGATION: {
    title: 'STATEMENT.PROLONGATION.TITLE',
    info: 'STATEMENT.PROLONGATION.INFO',
    status: 'PROLONGATION',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '26px',
      height: '26px',
    },
  },
  CREATION: {
    title: 'STATEMENT.CREATION.TITLE',
    info: 'STATEMENT.CREATION.INFO',
    status: 'CREATION',
    img: {
      url: '/assets/images/icons/icon-success.png',
      width: '22px',
      height: '18px',
    },
  },
  ERROR_LOGIN: {
    title: '',
    info: 'STATEMENT.ERROR_LOGIN.INFO',
    status: 'ERROR_LOGIN',
    img: {
      url: './assets/img/exclamationPoint.svg',
      width: '35px',
      height: '35px',
    },
  },
  UNKNOWN: {
    title: 'UNKNOWN',
    info: 'UNKNOWN',
    status: 'UNKNOWN',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '24px',
      height: '22px',
    },
  },
};

export const NOTICE_SUNCREDIT: LoaNotices = {
  APPROVED: {
    title: 'STATEMENT.APPROVED.TITLE',
    status: 'APPROVED',
    img: {
      url: '/assets/images/icons/icon-approved.png',
      width: '20px',
      height: '20px',
    },
  },
  ACTIVE: {
    title: 'STATEMENT.ACTIVE.TITLE',
    info: 'STATEMENT.ACTIVE.INFO',
    status: 'ACTIVE',
    img: {
      url: '/assets/images/icons/icon-approved.png',
      width: '20px',
      height: '20px',
    },
  },
  FREEZE: {
    title: 'STATEMENT.FREEZE.TITLE',
    info: 'STATEMENT.FREEZE.INFO',
    status: 'FREEZE',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  RESTRUCTURED: {
    title: 'STATEMENT.RESTRUCTURED.TITLE',
    info: 'STATEMENT.RESTRUCTURED.INFO',
    status: 'RESTRUCTURED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  RESTRUCTURING_SIGN: {
    title: 'STATEMENT.RESTRUCTURING_SIGN.TITLE',
    info: 'STATEMENT.RESTRUCTURING_SIGN.INFO',
    status: 'RESTRUCTURING_SIGN',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  EXPIRED: {
    title: 'STATEMENT.EXPIRED.TITLE',
    info: 'STATEMENT.EXPIRED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-expired.png',
      width: '20px',
      height: '22px',
    },
  },
  PENALTY: {
    title: 'STATEMENT.EXPIRED.TITLE',
    info: 'STATEMENT.EXPIRED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-expired.png',
      width: '20px',
      height: '22px',
    },
  },
  CALCULATION_STOPPED: {
    title: 'STATEMENT.CALCULATION_STOPPED.TITLE',
    info: 'STATEMENT.CALCULATION_STOPPED.INFO',
    status: 'EXPIRED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  TWO_DAYS_LEFT: {
    title: 'STATEMENT.TWO_DAYS_LEFT.TITLE',
    status: 'TWO_DAYS_LEFT',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  WILL_EXPIRED: {
    title: 'STATEMENT.WILL_EXPIRED.TITLE',
    status: 'WILL_EXPIRED',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  ACTION: {
    title: 'STATEMENT.ACTION.TITLE',
    info: 'STATEMENT.ACTION.INFO',
    status: 'ACTION',
    img: {
      url: '/assets/images/icons/icon-action.png',
      width: '22px',
      height: '22px',
    },
  },
  OUTSOURCE: {
    title: 'STATEMENT.OUTSOURCE.TITLE',
    info: 'STATEMENT.OUTSOURCE.INFO',
    status: 'OUTSOURCE',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  SOLD: {
    title: 'STATEMENT.SOLD.TITLE',
    info: 'STATEMENT.SOLD.INFO',
    status: 'SOLD',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
  PROLONGATION: {
    title: 'STATEMENT.PROLONGATION.TITLE',
    info: 'STATEMENT.PROLONGATION.INFO',
    status: 'PROLONGATION',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '26px',
      height: '26px',
    },
  },
  CREATION: {
    title: 'STATEMENT.CREATION.TITLE',
    info: 'STATEMENT.CREATION.INFO',
    status: 'CREATION',
    img: {
      url: '/assets/images/icons/icon-approved.png',
      width: '20px',
      height: '20px',
    },
  },
  ERROR_LOGIN: {
    title: '',
    info: 'STATEMENT.ERROR_LOGIN.INFO',
    status: 'ERROR_LOGIN',
    img: {
      url: './assets/img/exclamationPoint.svg',
      width: '35px',
      height: '35px',
    },
  },
  UNKNOWN: {
    title: 'UNKNOWN',
    info: 'UNKNOWN',
    status: 'UNKNOWN',
    img: {
      url: '/assets/images/icons/icon-attention.png',
      width: '20px',
      height: '22px',
    },
  },
};

export const LOAN_NOTICE_TOKEN = new InjectionToken<LoaNotices>('notices');
