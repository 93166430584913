import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '../../../../shared/components/dialog/dialog.module';
import { ButtonModule } from '../../../../shared/components/button/button.module';
import { AuthPhoneEditModalComponent } from './auth-phone-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [AuthPhoneEditModalComponent],
  imports: [CommonModule, TranslateModule, DialogModule, ButtonModule, ReactiveFormsModule, FormsModule, NgxMaskModule],
  exports: [AuthPhoneEditModalComponent],
})
export class AuthPhoneEditModalModule {}
