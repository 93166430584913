import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClearStoreService {
  private subscribe: Subject<any> = new Subject();

  public getSubject(): Observable<any> {
    return this.subscribe.asObservable();
  }

  public clear(): void {
    this.subscribe.next();
  }
}
