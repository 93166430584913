import * as Models from '../models/models';
import { ISelect } from '../interfaces/select.interface';

export class StaticDataConfig {
  static readonly SMS_CODE_LENGTH: number = 6;
  static readonly POSTAL_CODE_LENGTH: number = 5;
  static readonly MIN_PASS_LENGTH: number = 7;
  static readonly MAX_PASS_LENGTH: number = 15;
  static readonly PHONE_PREFIX: string = '380';
  static readonly DATE_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

  static regLocality: Array<ISelect> = [{ name: 'CITY' }, { name: 'URBAN_VILLAGE' }, { name: 'VILLAGE' }];
  static streetPrefix: Array<ISelect> = [
    { name: 'STREET' },
    { name: 'SIDE_STREET' },
    { name: 'AVENUE' },
    { name: 'BOULEVARD' },
    { name: 'AREA' },
  ];
  static purposes: Array<ISelect> = [
    { name: 'CONSUMER' },
    { name: 'REPAYMENT_LOANS' },
    { name: 'TREATMENT' },
    { name: 'REPAIR' },
    { name: 'GIFT' },
    { name: 'BILS' },
    { name: 'OTHER' },
  ];
  static education: Array<ISelect> = [
    { name: 'MIDDLE_SCHOOL' },
    { name: 'HIGH_SCHOOL' },
    { name: 'SPECIALIZED_HIGH_SCHOOL' },
    { name: 'INCOMPLETE_HIGHER_EDUCATION' },
    { name: 'HIGHER_EDUCATION' },
    { name: 'OTHER' },
  ];
  static affiliate: Array<string> = ['referrer', 'affiliate', 'clickid'];

  static readonly redirectPathByClientStage: Models.IPath = {
    form: {
      ACCOUNT: 'profile',
      UBKI_ID: 'profile',
      PERSONAL_DATA: 'busyness',
      EMPLOYMENT_DATA: 'passport',
      CLIENT: 'application',
    },
    system: {
      CLIENT: 'application',
      CARD: 'application',
      APPLICATION: 'application',
      LOAN: 'application',
      FINISHED_LOAN: 'application',
    },
    main: {
      CLIENT: 'loan',
      CARD: 'loan',
      APPLICATION: 'loan',
      LOAN: 'loan',
      FINISHED_LOAN: 'loan',
    },
  };
}
