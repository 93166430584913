import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItem } from '@libs/models/nav-item.interface';
import { environment } from '../../../../../environment';

@Component({
  selector: 'app-header-desktop-navigation',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: [`./templates/${environment.group}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesktopNavigationComponent {
  @Input() public items: NavItem[] = [];
}
