import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { VerificationType } from '../../../enums/verification-type.enum';
import { GroupType } from '../../../types/group.type';
import { WINDOW } from '../../injections/window';
import { environment } from '../../../environment';
import { SendEventHelper } from '../../../helpers/send-event.helper';

@Component({
  selector: 'app-resend-sms-widget',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: ['./resend-sms-widget.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendSmsWidgetComponent {
  @Input()
  public showSignMessage: boolean;

  @Input()
  public showAuthMessage: boolean;

  @Input()
  public timeOut: boolean;

  @Input()
  public showPhone: boolean;

  @Input()
  public smsViber: boolean = false;

  @Input()
  public group: GroupType;

  @Input()
  public classes: string = '';

  @Output()
  public readonly verifyWayEvent: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  public verificationType = VerificationType;

  constructor(@Inject(WINDOW) private readonly window: Window, private eventHelper: SendEventHelper) {}

  public resend(type): void {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'clicked button of resend sms start method resed');
    this.verifyWayEvent.emit(type);
    this.window['dataLayer'].push({
      event: 'auth_funnel_phone_click_button_method',
      text: type === this.verificationType.PHONE ? 'Повторний дзвінок' : 'Отримати код в CMC',
      funnel_step: 'Step 1',
    });
  }
}
