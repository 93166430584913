import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoanNoticeComponent } from './loan-notice.component';
import { ButtonModule } from '../button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PromoCodeModule } from '@libs/shared/components/promo-code/promo-code.module';

@NgModule({
  declarations: [LoanNoticeComponent],
  imports: [CommonModule, TranslateModule, ButtonModule, ReactiveFormsModule, PromoCodeModule],
  exports: [LoanNoticeComponent],
})
export class LoanNoticeModule {}
