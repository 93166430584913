import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoanService } from '../../services/loan.service';
import { LoanHistoryInterface } from '../../interfaces/loan-history.interface';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { isNonNull } from '../../helpers/is-non-null.helper';
import { ClearStoreService } from '../services/clear-store.service';
import { Store } from './store';

@Injectable()
export class LoanHistoryStorage extends Store {
  private historySource: BehaviorSubject<LoanHistoryInterface[] | null> = new BehaviorSubject<LoanHistoryInterface[] | null>(null);
  private isFirstLoading: boolean = false;

  constructor(private loanService: LoanService, public clearStoreService: ClearStoreService) {
    super(clearStoreService);
  }

  public getLoanHistory(): Observable<LoanHistoryInterface[]> {
    if (!this.historySource.value && !this.isFirstLoading) {
      this.loadFirstTime();
    }
    return this.historySource.asObservable();
  }

  public loadFirstTime(): void {
    this.isFirstLoading = true;
    this.loanService
      .getHistory()
      .pipe(
        filter(isNonNull),
        take(1),
        tap((history: LoanHistoryInterface[]) => this.setClient(history)),
        finalize(() => (this.isFirstLoading = false)),
      )
      .subscribe();
  }

  public setClient(history: LoanHistoryInterface[]): void {
    this.historySource.next(history);
  }

  public getStorageSubject(): BehaviorSubject<any> {
    return this.historySource;
  }
}
