export const environment = {
  production: true,
  name: 'STARFIN',
  group: 'STARFIN',
  hostUrl: `https://client.gateway.starfin.com.ua/v1/api/rest`,
  isTestPaymentEnabled: false,
  isInnValidationEnabled: true,
  recaptchaKEY_CODE: '6LdYHAkqAAAAAJKi4iCaOBzoQNYUvPJDf6EaIQIC',
  cookieDomain: '.starfin.com.ua',
  WEBSITE_URL: 'https://starfin.com.ua/',
  LOGO_SITE: '../assets/images/logo.png',
  LOGO_WHITE: '../assets/images/logo.png',
  CONTACT: 'tel:0800202233',
  GA_TAG: '2ZF3VH9DZ5',
  partnerLink: 'http://groshi.bez-otkazov.com/?utm_source=star&utm_medium=refferal&utm_campaign=lk&utm_term=',
};
