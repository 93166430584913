import { Component, Inject } from '@angular/core';
import { ContactConfig } from './configs/contact-config';
import { NavItem } from '@libs/models/nav-item.interface';
import { navItems } from './configs/nav-items';
import { IContact } from '@libs/models/models';
import { WINDOW } from '@libs/shared/injections/window';
import { IItem } from '@libs/shared/components/stepper-widget/stepper-widget.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  public readonly phone: IContact = ContactConfig.phones[0];
  public readonly navItems: NavItem[] = navItems;

  public steps: IItem[] = [
    { url: '/form/profile', text: 'Особиста інформація' },
    { url: '/form/busyness', text: 'Зайнятість та дохід' },
    { url: '/card/confirm', text: 'Підтвердження карти' },
  ];

  constructor(@Inject(WINDOW) private readonly window: Window) {
    this.window['dataLayer'] = this.window['dataLayer'] ?? [];
  }
}
