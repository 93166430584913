import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UnsubscribeService } from '../../services/unsubscribe.service';
import { GroupType } from '../../../types/group.type';
import { NamesEnum } from '../../../enums/names.enum';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../environment';
import { conditionalOperator } from '../../operators/conditional.operator';

export interface IItem {
  url: string;
  text?: string;
  previous?: boolean;
  current?: boolean;
  progress?: number;
}

@Component({
  selector: 'app-stepper-widget',
  templateUrl: `./templates/${environment.name === 'FINBAR' || environment.name === 'CREDISEND' ? 'BASE' : environment.group}/page.html`,
  styleUrls: [`./templates/${environment.name === 'FINBAR' || environment.name === 'CREDISEND' ? 'BASE' : environment.group}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService],
})
export class StepperWidgetComponent implements OnInit {
  @Input()
  public type: GroupType;
  @Input()
  public form: FormGroup;
  @Input()
  public controls: string[] = [];
  @Output()
  public readonly onVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public list: IItem[] = [
    { url: '/form/profile', text: 'Особиста інформація' },
    { url: '/form/busyness', text: 'Зайнятість та дохід' },
    { url: '/card/confirm', text: 'Підтвердження карти' },
  ];
  public routes$: Observable<IItem[] | null>;
  public totalProgress: number = 0;
  public formProgress: number = 0;

  constructor(
    @Self() private destroyStream$: UnsubscribeService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}

  @Input()
  public set steps(steps: IItem[]) {
    this.list = steps.length ? steps : this.routeConfig;
  }

  private get routeConfig(): IItem[] {
    return this.activatedRoute?.routeConfig?.children
      ? this.activatedRoute.routeConfig?.children.map((item: Route) => ({ url: item } as IItem)).filter((item: IItem) => item.url !== '**')
      : [];
  }

  public ngOnInit(): void {
    switch (true) {
      case this.type === NamesEnum.SUNCREDIT:
        this.activateFormStepper(this.list);
        break;
      default:
        this.activateStepper(this.list);
        break;
    }
  }

  private activateStepper(list: IItem[]): void {
    this.routes$ = this.router.events.pipe(
      conditionalOperator({
        condition: (event) => event instanceof NavigationEnd,
        trueCase: () => this.getStepperProgress(list),
      }),
    );
  }

  private getStepperProgress(list: IItem[]): Observable<IItem[]> {
    return of(list).pipe(
      map((items: IItem[]) => {
        return items.map((item: IItem, i: number) => ({
          url: item.url,
          text: item.text,
          previous: item.previous ? item.previous : false,
          progress: item.progress ? item.progress : 0,
          current: item.url === this.location.path(),
        }));
      }),
      map((data: IItem[]) => {
        this.totalProgress = this.getProgress(data);
        const value = data.some((item) => item.url === this.location.path());
        this.onVisibleChange.emit(value);
        this.cd.detectChanges();
        return value ? data : null;
      }),
      takeUntil(this.destroyStream$),
    );
  }

  private getProgress(data: IItem[]): number {
    return Math.round(((data.findIndex((item: IItem) => item.url === this.location.path()) + 1) / data.length) * 100);
  }

  private activateFormStepper(list: IItem[]): void {
    this.routes$ = this.getStepperProgress(this.modifyList(list)); // for fist time
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          let validCount = 0;
          for (const key of this.controls) {
            if (this.form.controls[key] && this.form.controls[key].valid) {
              validCount++;
            }
          }
          this.formProgress = (validCount / this.controls.length) * 100;
          this.routes$ = this.getStepperProgress(this.modifyList(list, this.formProgress));
          this.cd.detectChanges();
        }),
        takeUntil(this.destroyStream$),
      )
      .subscribe();
  }

  private modifyList(list: IItem[], itemProgress: number = 0): IItem[] {
    const array = [...list];
    const index = list.findIndex((item) => item.url === this.location.path());
    if (index <= 1) {
      for (let i = 0; i < index; i++) {
        if (array[i].url !== this.location.path()) {
          array[i] = { ...array[i], previous: true, progress: 100 };
        }
      }
      array[index].progress = Math.round(itemProgress);
    }
    return array;
  }
}
