import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private document, private meta: Meta, private translate: TranslateService, private title: Title) {}

  public createCanonicalURL(): void {
    const link = this.document.getElementById('canonical');
    if (link) {
      link.setAttribute('href', this.document.URL.split('?')[0]);
      this.meta.updateTag({
        property: 'og:url',
        content: this.document.URL.split('?')[0],
      });
    }
  }

  public updateMetaTags(key: string): void {
    this.translate
      .stream(key)
      .pipe(take(1))
      .subscribe((res: { TITLE: string; META: string }) => {
        if (res) {
          const { TITLE, META } = res;
          this.title.setTitle(TITLE);
          this.meta.updateTag({ name: 'description', content: META });
          this.meta.updateTag({ property: 'og:title', content: TITLE });
          this.meta.updateTag({ property: 'og:description', content: META });
        }
      });
  }
}
