import { NgModule } from '@angular/core';
import { PromoCodeModule } from '@libs/shared/components/promo-code/promo-code.module';
import { LoanScheduleWidgetModule } from '../../shared/components/loan-schedule-widget/loan-schedule-widget.module';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../shared/components/dialog/dialog.module';
import { ToasterWidgetModule } from '@libs/shared/components/toaster-widget/toaster-widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LoanEditorModalComponent } from './loan-editor-modal.component';
import { CalculateWidgetModule } from '../../shared/components/calculate-widget/calculate-widget.module';

@NgModule({
  declarations: [LoanEditorModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PromoCodeModule,
    DialogModule,
    ToasterWidgetModule,
    CalculateWidgetModule,
    TranslateModule,
    ButtonModule,
    LoanScheduleWidgetModule,
  ],
  exports: [LoanEditorModalComponent],
})
export class LoanEditorModalModule {}
