import { InjectionToken } from '@angular/core';
import { ILoanProduct } from '../models/models';

export const PRODUCT_CONFIG: ILoanProduct = {
  minTerm: 3,
  maxTerm: 30,
  minAmount: 1000,
  maxAmount: 20000,
  termStep: 1,
  interest: 2.5,
  prolongationMinTerm: 7,
  prolongationMaxTerm: 14,
  firstPeriodInterest: 0.5,
  isFirst: false,
  requiredPhoto: false,
  is360: false,
};

export const PRODUCT_CONFIG_TOKEN = new InjectionToken<ILoanProduct>('productConfig');
