import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ILoanProduct } from '../../../models/models';
import { IScheduleEvent } from '../../../interfaces/schedule-event';
import { ICalculatePreview } from '../../../interfaces/calculate-preview.interface';
import { environment } from '../../../environment';

@Component({
  selector: 'app-loan-schedule-widget[schedule]',
  templateUrl: './loan-schedule-widget.component.html',
  styleUrls: [`./templates/${environment.group}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanScheduleWidgetComponent implements OnChanges, OnDestroy {
  @Input()
  public type: 'base' | 'avans' | 'starfin' | 'suncredit' | string = 'base';

  @Input()
  public preview: ICalculatePreview;

  @Input()
  public schedule: IScheduleEvent[];

  @Input()
  public product: ILoanProduct;

  @Input()
  public character: 'FICTION' | 'WORKTABLE' = 'WORKTABLE';

  public activeTab: string = '';
  public showPaidColumn: boolean = false;
  private documentClick$: Subscription;

  constructor(private cd: ChangeDetectorRef, private eRef: ElementRef, private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      this.documentClick$ = fromEvent(document, 'click', { capture: true })
        .pipe(throttleTime(50))
        .subscribe((event: Event) => {
          if (this.activeTab && event.target !== this.eRef.nativeElement && !this.eRef.nativeElement.contains(event.target)) {
            this.activeTab = '';
            this.cd.detectChanges();
          }
        });
    });
  }

  public showDetails(type: 'SCHEDULE' | 'DETAILS'): void {
    this.activeTab = type !== this.activeTab ? type : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.showPaidColumn = changes?.schedule?.currentValue[0]?.paidAmount >= 0 || changes?.schedule?.currentValue[0]?.overdue;
  }

  public ngOnDestroy(): void {
    if (this.documentClick$) {
      this.documentClick$.unsubscribe();
    }
  }
}
