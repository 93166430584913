<label #trigger [class.not-allowed]="disabled" [class]="checkboxClasses" class="d-flex align-items-start">
  <input (change)="onChanged($event)" [checked]="isChecked" [disabled]="disabled" type="checkbox" />
  <span class="checkbox-inner d-flex justify-content-center align-items-center">
    <span [matRippleCentered]="true" [matRippleColor]="rippleColor" [matRippleTrigger]="trigger" class="checkbox-ripple" matRipple></span>
    <span
      *ngIf="!checkImg?.url && !uncheckImg?.url"
      [class.checked]="isChecked"
      [ngClass]="{ 'ng-valid': control?.valid, 'in-valid': control?.invalid }"
      [style.backgroundColor]="isChecked ? background : backgroundColorAlways ? background : '#ffffff'"
      [style.border]="isChecked ? '1px solid ' + border : '1px solid #E0E1E4'"
      class="checkbox"
    ></span>

    <span
      *ngIf="checkImg?.url && uncheckImg?.url"
      [ngClass]="{ 'ng-valid': control?.valid, 'in-valid': control?.invalid }"
      [style.background-image]="
        isChecked ? 'url(' + checkImg.url + ')' : control?.invalid ? 'url(' + errorImg.url + ')' : 'url(' + uncheckImg.url + ')'
      "
      [style.height]="checkImg.height || ''"
      [style.width]="checkImg.width || ''"
      class="checkbox"
    ></span>
  </span>
  <div *ngIf="!template" class="d-flex flex-column">
    <span [innerHTML]="label" class="label-title fs-12"></span>
    <span *ngIf="description" [class]="labelClasses" class="label-description">{{ description }}</span>
  </div>
  <div *ngIf="template" [class]="labelClasses" [innerHTML]="template" class="label-description"></div>
</label>
