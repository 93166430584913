<section *ngIf="routes$ | async as routes;" class="stepper">
  <div class="stepper-wrapper">
    <div class="progression d-flex justify-content-between align-items-center">
      <div class="progression-line">
        <div [style.width]="totalProgress + '%'" class="progression-line-progress"></div>
      </div>
      <div class="progress-percent">{{ totalProgress }}%</div>
    </div>
    <div class="steps d-flex align-items-center justify-content-between">
      <div *ngFor="let item of routes; index as i" [class.current]="item.current" class="steps-item d-flex align-items-center">
        <span class="steps-icon d-flex justify-content-center align-items-center">{{ i + 1 }}</span>
        <span class="steps-label">{{ item.text }}</span>
      </div>
    </div>
  </div>
</section>
