import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClientStore } from '../shared/storage/client.store';
import { IdentOptionsEnum } from '../enums/ident-options.enum';
import * as Config from '../configs/general-config';

@Injectable()
export class IdentGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private clientStore: ClientStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.clientStore.getClientIdentOptions().map((item: IdentOptionsEnum) => {
      if (item.length) {
        let redirectUrl;
        if (item.includes(IdentOptionsEnum.PHONE)) {
          redirectUrl = [Config.RouterConfig.system, Config.RouterConfig.financeNumber];
        }
        // else if (client.identOptions.includes(IdentOptionsEnum.PHOTO)) {
        //     redirectUrl = [Config.RouterConfig.system-group, Config.RouterConfig.scanner];
        // }
        else {
          return true;
        }
        this.router.navigate(redirectUrl);
        return false;
      }
      return true;
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}
