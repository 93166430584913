<ng-container [ngSwitch]="character">
  <section *ngSwitchCase="'FICTION'" [ngClass]="'schedule_' + type" class="schedule">
    <div [class.schedule-header_open]="activeTab" class="schedule-header">
      <div (click)="showDetails('SCHEDULE')" [class.active]="activeTab === 'SCHEDULE'" class="schedule-btn" matRipple>
        <span class="label">{{ 'LOAN_SCHEDULE.SCHEDULE' | translate }}</span>
      </div>
      <div (click)="showDetails('DETAILS')" [class.active]="activeTab === 'DETAILS'" class="schedule-btn" matRipple>
        <span class="label">{{ 'LOAN_SCHEDULE.DISPLAY' | translate }}</span>
      </div>
    </div>
    <div class="schedule-container">
      <div *ngIf="activeTab === 'SCHEDULE'" class="schedule-content">
        <div [ngClass]="'graph_' + type" class="graph">
          <div class="graph-header mb-2 d-flex justify-content-between align-items-center">
            <span class="graph-header-label text-left">{{ 'LOAN_SCHEDULE.PAYMENT_DATE' | translate }}</span>
            <span class="graph-header-label text-center">{{ 'LOAN_SCHEDULE.INTEREST' | translate }}</span>
            <span class="graph-header-label text-right">{{ 'LOAN_SCHEDULE.AMOUNT' | translate }}</span>
          </div>
          <div class="graph-content">
            <div *ngFor="let item of schedule; index as i" class="graph-row d-flex justify-content-between align-items-center">
              <span class="graph-value text-left fw-light">{{ item.date }}</span>
              <span class="graph-value text-center fw-light">{{ item.rate }}%</span>
              <span class="graph-value text-right fw-regular">{{ item.amount }} грн</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeTab === 'DETAILS'" class="schedule-content">
        <div class="preview">
          <div class="preview-row d-flex justify-content-between align-items-center mb-2">
            <span class="preview-label">{{ 'LOAN_SCHEDULE.TOTAL_COSTS' | translate }}</span>
            <span class="preview-value text-right">{{ preview.percentAmount }} грн</span>
          </div>
          <div class="preview-row d-flex justify-content-between align-items-center mb-2">
            <span class="preview-label">{{ 'LOAN_SCHEDULE.INTEREST_DAY' | translate }}</span>
            <span class="preview-value text-right">{{ product.interest }}%</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngSwitchCase="'WORKTABLE'" [ngClass]="'schedule_' + type" class="schedule">
    <div [class.schedule-header_open]="activeTab" class="schedule-header">
      <div (click)="showDetails('SCHEDULE')" [class.active]="activeTab === 'SCHEDULE'" class="schedule-btn" matRipple>
        <span class="label">{{ 'LOAN_SCHEDULE.SCHEDULE' | translate }}</span>
      </div>
    </div>
    <div class="schedule-container">
      <div *ngIf="activeTab === 'SCHEDULE'" class="schedule-content">
        <div class="graph">
          <table [ngClass]="'graph-table_' + type" class="graph-table">
            <thead class="graph-table-header">
              <tr>
                <td class="graph-label text-left" width="35%">
                  {{ 'LOAN_SCHEDULE.PAYMENT_DATE' | translate }}
                </td>
                <td
                  [ngStyle]="{
                    width: showPaidColumn ? '40%' : '65%',
                    'text-align': showPaidColumn ? 'center ' : 'right'
                  }"
                  class="graph-label"
                >
                  {{ 'LOAN_SCHEDULE.AMOUNT' | translate }}
                </td>
                <td *ngIf="showPaidColumn" class="graph-label text-right" width="25%">
                  {{ 'LOAN_SCHEDULE.PAID' | translate }}
                </td>
              </tr>
            </thead>
          </table>
          <div class="graph-content">
            <table class="graph-table">
              <tr
                *ngFor="let item of schedule; index as i; let first = first"
                [class.graph-row_active]="item?.overdue"
                [class.graph-row_current]="item?.current"
                class="graph-row"
              >
                <td class="graph-value text-left" width="35%">
                  {{ item.date }}
                </td>
                <td
                  [ngStyle]="{
                    width: showPaidColumn ? '40%' : '65%',
                    'text-align': showPaidColumn ? 'center' : 'right'
                  }"
                  class="graph-value"
                >
                  {{ item.amount }} грн
                </td>
                <td *ngIf="item?.overdue || item?.current || item.paidAmount" class="graph-value text-right" width="25%">
                  {{ item?.paidAmount }} грн
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
