import { NavItem } from '@libs/models/nav-item.interface';

export const navItems: NavItem[] = [
  {
    label: 'NAVIGATION.LOAN',
    route: 'main/loan',
    img: {
      url: '/assets/images/icons/icon-loan.svg',
      width: '22px',
      height: '18px',
    },
  },
  {
    label: 'NAVIGATION.HISTORY',
    route: 'main/history',
    img: {
      url: '/assets/images/icons/icon-loan-history.svg',
      width: '26px',
      height: '18px',
    },
  },
  {
    label: 'NAVIGATION.PROFILE',
    route: 'main/profile',
    img: {
      url: 'assets/images/icons/icon-profile.svg',
      width: '22px',
      height: '22px',
    },
  },
  {
    label: 'NAVIGATION.UPLOAD',
    route: 'main/upload',
    img: {
      url: '/assets/images/icons/icon-load-document.svg',
      width: '18px',
      height: '22px',
    },
  },
];
