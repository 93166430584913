import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterWidgetModule } from '../toaster-widget/toaster-widget.module';
import { CalculateWidgetComponent } from './containers/calculate-widget.component';

@NgModule({
  declarations: [CalculateWidgetComponent],
  exports: [CalculateWidgetComponent],
  imports: [CommonModule, MatSliderModule, TranslateModule, ReactiveFormsModule, FormsModule, ToasterWidgetModule],
})
export class CalculateWidgetModule {}
