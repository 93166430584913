import { Inject, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlService } from './url.service';
import { Observable } from 'rxjs';
import { VerificationType } from '../enums/verification-type.enum';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { EnvironmentType } from '../models/environment.type';
import { SendEventHelper } from '../helpers/send-event.helper';

@Injectable()
export class UserVerificationService {
  public linkIvr: string;
  public platform: string;
  constructor(
    @Inject('environment') private environment: EnvironmentType,
    private http: HttpClient,
    private urlService: UrlService,
    private authService: AuthenticationService,
    private _zone: NgZone,
    private eventHelper: SendEventHelper,
  ) {
    this.linkIvr = !this.environment.production ? this.urlService.testVerificationPhoneCallUrl : this.urlService.verificationPhoneCallUrl;
    this.getPlatform();
  }

  public getPlatform(): void {
    this.platform = Capacitor.getPlatform().toUpperCase();
  }

  public sendSmsCode(
    phone: number | string,
    action: string,
    withCredentials = false,
    verificationType?: VerificationType,
  ): Observable<any> {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      verificationType,
      scope: this.environment.name,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.verificationUrl, body, { params });
  }

  public getIvrCall(
    phone: number | string,
    action: string,
    reCaptchaToken: string,
    verificationType?: VerificationType,
    marketingConsent = true,
  ) {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      verificationType,
      reCaptchaToken,
      scope: this.environment.name,
      marketingConsent,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    this.eventHelper.send(phone, 'started ivr-call');
    return this.http.post(this.urlService.ivrCallUrl, body);
  }

  public ivrUnsubscribe(token: any, isChangedPhone: boolean = false): Observable<Object> {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'started ivr-unsubscribe');
    const changePhone = isChangedPhone ? `&change-phone=${isChangedPhone}` : '';
    return this.http.get(`${this.urlService.ivrUnsubscribeUrl}?token=${token}${changePhone}`);
  }

  public getServerSentEventIvr(item: any): Observable<any> {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'started ivr-subscribe');
    return Observable.create((observer) => {
      const eventSource = new EventSource(`${this.urlService.ivrSubscribeUrl}?token=${item.token}`);
      eventSource.addEventListener('message', (event) => {
        if (event.data) {
          this.eventHelper.send(
            sessionStorage.getItem('phone'),
            `eventSource data: ${String(event.data).replace('{', '').replace('}', '')}`,
          );
          observer.next(event);
          eventSource.close();
        }
      });
      eventSource.addEventListener('error', (event) => {
        observer.error(event);
        this.eventHelper.send(sessionStorage.getItem('phone'), `ivr-subscribe -> eventSource error`);
      });
    });
  }

  public sendSmsCodeWithCaptcha(
    phone: number | string,
    action: string,
    reCaptchaToken: string,
    withCredentials = false,
    verificationType?: VerificationType,
  ): Observable<any> {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      reCaptchaToken,
      scope: this.environment.name,
      verificationType,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.verificationUrl, body, { params });
  }

  public confirmSmsCode(
    phone: string,
    action: string,
    code: string,
    withCredentials = false,
    marketingConsent = true,
    verificationType: VerificationType,
  ): Observable<any> {
    const body = {
      phone,
      action,
      code,
      marketingConsent,
      verificationType,
      scope: this.environment.name,
      platform: this.platform,
    };
    this.eventHelper.send(sessionStorage.getItem('phone'), 'started /verifications/confirmations');
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.confirmationsUrl, body, { params });
  }
}
