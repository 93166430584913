import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { concatMap, filter, switchMap, takeUntil, tap } from 'rxjs/internal/operators';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { VerificationActionType } from '../../../../enums/verification-action-type.enum';
import { VerificationType } from '../../../../enums/verification-type.enum';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ClientStore } from '../../../../shared/storage/client.store';
import { TokenResponse } from '../../../../models/token-response.model';
import { ClientModel } from '../../../../models/client.model';
import { MaskApplierService } from 'ngx-mask';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserVerificationService } from '../../../../services/user-verification.service';
import { GroupType } from '../../../../types/group.type';
import { CountdownContainerComponent } from '../../../../shared/components/countdown-container/countdown-container.component';
import { NamesEnum } from '../../../../enums/names.enum';
import { EnvironmentType } from '../../../../models/environment.type';
import { WINDOW } from '../../../../shared/injections/window';
import { environment } from '../../../../environment';
import { SendEventHelper } from '../../../../helpers/send-event.helper';

@Component({
  selector: 'app-auth-confirm',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: [`./templates/${environment.group}/style.sass`],
  providers: [UnsubscribeService],
})
export class AuthConfirmComponent implements OnInit {
  @Input()
  public group!: GroupType;

  @Input()
  public showPhone: boolean = true;

  @Input()
  public smsViber: boolean = false;

  @Input()
  public phone: string;

  @Input()
  public verificationType: VerificationType;

  @Output()
  public readonly verifyWayEvent: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  @Output()
  public readonly editPhoneEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly showPhoneEditorEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly redirectClientEvent: EventEmitter<ClientModel> = new EventEmitter<ClientModel>();

  @Output()
  public readonly timeGoneEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public form: FormGroup;
  public isDisabled: boolean = true;
  public loginAction = VerificationActionType.LOGIN;
  public breakpointTimeOut: boolean = false;
  public timeOut: boolean = false;
  public initialState: { count: number; breakpoint?: number } = {
    count: 60,
    breakpoint: this.group !== NamesEnum.AVANS ? 59 : 10, // ( count - 1 ) last value emit
  };
  public ENV!: EnvironmentType;
  @ViewChild(CountdownContainerComponent)
  private counterContainer: CountdownContainerComponent;
  private MIN_CODE_LENGTH: number = 4;
  private withCredentials: boolean = false;

  constructor(
    @Inject('environment') private environment: EnvironmentType,
    @Inject(WINDOW) private readonly window: Window,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    @Self() private destroyStream$: UnsubscribeService,
    private maskService: MaskApplierService,
    private verificationService: UserVerificationService,
    private authService: AuthenticationService,
    private clientStore: ClientStore,
    private eventHelper: SendEventHelper,
  ) {
    this.ENV = this.environment;
  }

  public get phoneNumber(): string {
    const masks: { [key: string]: string } = {
      AVANS: '+00 000 000 00 00',
      STARFIN: '+00(000) 000 00 00',
      SUNCREDIT: '+00(000) 000 00 00',
      BASE: '00000 000 0000',
    };
    return this.maskService.applyMask(this.phone.toString(), masks[this.group]);
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public editPhone(): void {
    this.editPhoneEvent.emit(); // use only for ZE and Banana
  }

  public breakpointOutHandler($event: boolean): void {
    this.breakpointTimeOut = $event;
    this.timeGoneEvent.emit($event);
  }

  public timeOutEventHandler($event: boolean): void {
    this.timeOut = $event;
  }

  public wayVerificationHandler(type: VerificationType): void {
    // do not change order
    this.verificationType = type;
    this.verifyWayEvent.emit(type);
    this.counterContainer.restartTimer();
    this.timeOut = false;
    this.breakpointTimeOut = false;
    this.changeValidator();
    this.eventHelper.send(this.phone, 'method wayVerificationHandler');
    this.changeDetector.detectChanges();
  }

  public changeValidator(): void {
    this.MIN_CODE_LENGTH = this.verificationType === 'SMS' ? 3 : 4;
    const code = this.form.get('code');
    code.setValue(null);
    code.setValidators([Validators.required, Validators.minLength(this.MIN_CODE_LENGTH)]);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      code: [null, Validators.compose([Validators.required, Validators.minLength(this.MIN_CODE_LENGTH)])],
      agreement: this.formBuilder.group({
        agree: [true, Validators.requiredTrue],
        marketingConsent: [true],
      }),
    });
    this.changeValidator();

    this.form.valueChanges
      .pipe(
        filter(
          () =>
            Boolean(this.form.get('code')?.value) &&
            this.form.get('code')?.value?.trim().toUpperCase().length === this.MIN_CODE_LENGTH &&
            this.form.controls.agreement.get('agree').value,
        ),
        concatMap(() =>
          this.verificationService.confirmSmsCode(
            this.phone,
            VerificationActionType.LOGIN,
            this.verificationType === 'SMS'
              ? 'W' + this.form.get('code').value.trim().toUpperCase()
              : this.form.get('code').value.trim().toUpperCase(),
            this.withCredentials,
            this.form.controls.agreement.get('marketingConsent').value,
            this.verificationType,
          ),
        ),
        switchMap((tokenResp: TokenResponse) => this.authService.loginUserByVerificationToken(tokenResp.token, this.phone)),
        tap((tokenResp: TokenResponse) => {
          if (tokenResp.redirectUrl) this.window.location.href = tokenResp.redirectUrl;
        }),
        tap((tokenResp: TokenResponse) => this.authService.setToken(tokenResp)),
        switchMap(() => this.clientStore.getClient()),
        takeUntil(this.destroyStream$),
      )
      .subscribe(
        (client: ClientModel) => {
          this.redirectClientEvent.emit(client);
        },
        () => {
          this.form.get('code').setValue(null);
          this.initForm();
          this.window['dataLayer'].push({
            event: 'auth_error',
            id: 'sms_verification',
            text: 'Введено невірний код підтвердження',
          });
        },
      );
  }
}
