import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NavItem } from '@libs/models/nav-item.interface';
import { IContact, ISocial } from '../../../models/models';
import { Schedule } from '../../../types/shedule.type';
import { environment } from '../../../environment';
import { EnvironmentType } from '../../../models/environment.type';

@Component({
  selector: 'app-footer',
  templateUrl: `./templates/${environment.name}/template.html`,
  styleUrls: [`./templates/${environment.name}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input()
  public isWebSite: boolean = false;

  @Input()
  public isSmallFooter: boolean = false;

  @Input()
  public routes: NavItem[] = [];

  @Input()
  public phones: IContact[] = [];

  @Input()
  public emails: IContact[] = [];

  @Input()
  public schedule: Schedule[] = [];

  @Input()
  public social: ISocial[] = [];

  @Input()
  public mod: boolean = false;

  public year: number = new Date().getFullYear();
  public smallTextForPhone: string = '<span class="small">(безкоштовно)</span>';

  constructor(
    @Inject('environment') private environment: EnvironmentType,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {}

  public openChat(): void {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { HelpCrunchOpenChat: 1 },
      })
      .toString();
    this.location.go(url);
  }

  public thirdPartyClickHandler(url: string): void {
    if (!localStorage.getItem('isThirdPartyClick')) {
      localStorage.setItem('isThirdPartyClick', new Date().toISOString());
    }
  }

  public toHomePage(): any {
    this.router.navigate(['/']).then(() => (document.body.scrollTop = 0));
  }
}
