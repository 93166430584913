<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="'TIMER'" [ngClass]="type" class="counter text-center">
    {{ currentCount }}
  </div>

  <div *ngSwitchCase="'COUNTDOWN'" class="countdown">
    <div class="countdown-spinner d-flex justify-content-center">
      <div class="spinner-container">
        <div [style.border-width.px]="initialState.strokeWidth" class="spinner-background">
          {{ currentCount }}
        </div>
        <mat-progress-spinner
          [diameter]="initialState.diameter"
          [strokeWidth]="initialState.strokeWidth"
          [value]="progress"
          color="primary"
          mode="determinate"
        >
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</ng-container>
