import { AfterViewInit, Component, EventEmitter, forwardRef, Inject, Injector, INJECTOR, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public isChecked: boolean = false;

  @Input()
  public label: string = '';

  @Input()
  public description: string = '';

  @Input()
  public disabled: boolean = false;

  @Input()
  public template: any;

  @Input()
  public labelClasses: string = '';

  @Input()
  public checkboxClasses: string;

  @Input()
  public rippleColor: string = '#a7b9ea';

  @Input() public checkImg: {
    url: string;
    height?: string;
    width?: string;
  };

  @Input() public uncheckImg: {
    url: string;
    height?: string;
    width?: string;
  };

  @Input() public errorImg: {
    url: string;
    height?: string;
    width?: string;
  };

  @Input()
  public background: string = '#5457FF';

  @Input()
  public backgroundColorAlways: boolean = false;

  @Input()
  public border: string = 'rgba(0, 0, 0, 0.2)';

  @Output()
  public readonly changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public control: AbstractControl | null;

  constructor(@Inject(INJECTOR) private injector: Injector) {}

  public ngAfterViewInit(): void {
    this.getControl();
  }

  public regOnChange = (_: any) => {};

  public regOnTouched = (_: any) => {};

  public registerOnChange(fn: any): void {
    this.regOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.regOnTouched = fn;
  }

  public writeValue(value: any): void {
    this.isChecked = value;
  }

  public onChanged($event): void {
    this.isChecked = $event.target && $event.target.checked;
    this.regOnChange($event.target.checked);
  }

  private getControl(): void {
    setTimeout(() => {
      this.control = this.injector.get(NgControl)?.control;
    });
  }
}
