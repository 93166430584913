import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperWidgetComponent } from './stepper-widget.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [StepperWidgetComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [StepperWidgetComponent],
})
export class StepperWidgetModule {}
