import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take, tap } from 'rxjs/operators';
import { ICalculatePreview } from '../../../interfaces/calculate-preview.interface';
import { ProductStore } from '../../storage/product.store';
import { ILoanProduct } from '../../../models/models';
import { LoanEditorModalComponent } from '../../../modals/loan-editor-modal/loan-editor-modal.component';
import { GroupType } from '../../../types/group.type';
import { environment } from '../../../environment';

@Component({
  selector: 'app-loan-preview[preview][type]',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: ['./loan-preview.component.sass', `./templates/${environment.group}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanPreviewComponent {
  @Input()
  public type: 'small' | 'middle' = 'middle';

  @Input()
  public preview: ICalculatePreview;

  @Input()
  public isLogged: boolean;

  @Input()
  public group: GroupType;

  public showDetails: boolean = false;

  public animationState: string = 'in';

  constructor(private dialog: MatDialog, private productStore: ProductStore) {}

  public toggleShowDetails(): void {
    this.showDetails = !this.showDetails;
  }

  public openModal(): void {
    this.productStore
      .get()
      .pipe(
        tap((product: ILoanProduct) => this.showModal(product)),
        take(1),
      )
      .subscribe();
  }

  private showModal(product: ILoanProduct): void {
    const types: { [key: string]: { prefix: string; size: string } } = {
      AVANS: { prefix: 'avans', size: '960px' },
      STARFIN: { prefix: 'starfin', size: '580px' },
      SUNCREDIT: { prefix: 'suncredit', size: '590px' },
      BASE: { prefix: 'base', size: '330px' },
    };
    this.dialog.open(LoanEditorModalComponent, {
      panelClass: ['apply-modal', `apply-modal-${types[this.group].prefix}`],
      width: types[this.group].size,
      maxWidth: '90vw',
      maxHeight: '100vh',
      data: {
        product: product,
        group: this.group,
      },
    });
  }
}
