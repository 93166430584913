import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Config from '../../configs/general-config';
import { ClientModel } from '../../models/client.model';
import { IdentOptionsEnum } from '../../enums/ident-options.enum';
import { InnValidator } from '../validators/inn.validator';
import { ClientStageEnum } from '../../enums/client-stage.enum';
import { NamesEnum } from '../../enums/names.enum';
import { EnvironmentType } from '../../models/environment.type';
import { GroupType } from '../../types/group.type';

export abstract class DataForm {
  private static fb: FormBuilder = new FormBuilder();

  public static getPersonalFormGroup(client: ClientModel, identOptions: IdentOptionsEnum, ENV: EnvironmentType): FormGroup {
    const isDataFromUbkiId = client.stage === ClientStageEnum.UBKI_ID;
    return this.fb.group({
      lastName: [
        { value: null, disabled: isDataFromUbkiId },
        Validators.compose([Validators.required, Validators.pattern(Config.RegularExpression.LAST_NAME)]),
      ],
      firstName: [
        { value: null, disabled: isDataFromUbkiId },
        Validators.compose([Validators.required, Validators.pattern(Config.RegularExpression.FIRST_NAME)]),
      ],
      middleName: [
        { value: null, disabled: isDataFromUbkiId },
        Validators.compose([Validators.required, Validators.pattern(Config.RegularExpression.MIDDLE_NAME)]),
      ],
      inn: [
        { value: '', disabled: isDataFromUbkiId },
        [Validators.required, Validators.pattern(Config.RegularExpression.INN), new InnValidator(ENV)],
      ],
      isNewPassport: [{ value: false, disabled: isDataFromUbkiId }],
      passportNumber: [{ value: null, disabled: isDataFromUbkiId }],
      newPassportNumber: [{ value: null, disabled: isDataFromUbkiId }],
      email: [
        null,
        Validators.compose([
          ...(ENV.group === NamesEnum.AVANS ? [Validators.required] : []),
          Validators.email,
          Validators.pattern(Config.RegularExpression.mailPattern),
        ]),
      ],
    });
  }

  public static getBusinessFormGroup(group: GroupType): FormGroup {
    switch (true) {
      case group === NamesEnum.AVANS:
        return this.fb.group({
          education: ['', [Validators.required]],
          busynessType: [''],
          secretWordType: ['', [Validators.required]],
          secretWord: ['', [Validators.required, Validators.maxLength(72)]],
          incomeRange: ['', [Validators.required]],
          relativePhone: [380, [Validators.required, Validators.pattern(Config.RegularExpression.MOBILE_PHONE)]],
        });
      default:
        return this.fb.group({
          education: ['', [Validators.required]],
          busynessType: [''],
          monthlyIncome: [null, [Validators.required, Validators.pattern(Config.RegularExpression.ONLY_NUMBERS)]],
          relativePhone: [380, [Validators.required, Validators.pattern(Config.RegularExpression.MOBILE_PHONE)]],
        });
    }
  }

  public static getLoginFormGroup(): FormGroup {
    return this.fb.group({
      phone: [380, Validators.compose([Validators.required, Validators.pattern(Config.RegularExpression.MOBILE_PHONE)])],
    });
  }

  public static getFreePaymentFormGroup(ENV: EnvironmentType): FormGroup {
    return this.fb.group({
      inn: ['', [new InnValidator(ENV), Validators.pattern(Config.RegularExpression.INN), Validators.required]],
      contractNumber: [null, Validators.compose([Validators.required])],
      amount: [null, Validators.compose([Validators.required, Validators.min(0.01), Validators.max(100000)])],
    });
  }

  public static getFreePaymentWidthPhoneFormGroup(): FormGroup {
    return this.fb.group({
      contractNumber: [null, Validators.compose([Validators.required])],
      amount: [null, Validators.compose([Validators.required, Validators.min(0.01), Validators.max(100000)])],
    });
  }

  public static getFreePaymentFormGroupWithPhone(): FormGroup {
    return this.fb.group({
      relativePhone: [380, Validators.compose([Validators.required, Validators.pattern(Config.RegularExpression.MOBILE_PHONE)])],
      amount: [null, Validators.compose([Validators.required, Validators.min(0.01), Validators.max(100000)])],
    });
  }

  public static getClosedDocuments(ENV: EnvironmentType): FormGroup {
    return this.fb.group({
      inn: ['', [Validators.required, Validators.pattern(Config.RegularExpression.INN), new InnValidator(ENV)]],
      contractNumber: [null, Validators.compose([Validators.required])],
    });
  }

  public static getBookingForm(group: GroupType, MIN_CODE_LENGTH: number): FormGroup {
    switch (true) {
      case group === NamesEnum.AVANS:
        return this.fb.group({
          calculate: this.fb.group({
            amount: [null],
            term: [null],
          }),
          code: [null, [Validators.minLength(MIN_CODE_LENGTH)]],
          cardId: [null, [Validators.required]],
          purpose: [null, [Validators.required]],
          pep: [false, [Validators.required]],
        });
      default:
        return this.fb.group({
          calculate: this.fb.group({
            amount: [null],
            term: [null],
          }),
          code: [null, Validators.compose([Validators.minLength(MIN_CODE_LENGTH)])],
          cardId: [null, [Validators.required]],
        });
    }
  }
}
