<section [formGroup]="form" class="auth-agreement">
  <div class="auth-agreement-content">
    <div class="mb-2">
      <app-checkbox
        [checkImg]="{
          url: './assets/images/icons/check.svg',
          width: imgSize,
          height: imgSize
        }"
        [errorImg]="{
          url: './assets/images/icons/uncheckError.svg',
          width: imgSize,
          height: imgSize
        }"
        [labelClasses]="labelClasses"
        [template]="accept"
        [uncheckImg]="{
          url: './assets/images/icons/uncheck.svg',
          width: imgSize,
          height: imgSize
        }"
        formControlName="agree"
      ></app-checkbox>
    </div>
    <div>
      <app-checkbox
        [checkImg]="{
          url: './assets/images/icons/check.svg',
          width: imgSize,
          height: imgSize
        }"
        [description]="'AGREEMENT.INFO' | translate"
        [labelClasses]="labelClasses"
        [uncheckImg]="{
          url: './assets/images/icons/uncheck.svg',
          width: imgSize,
          height: imgSize
        }"
        formControlName="marketingConsent"
      ></app-checkbox>
    </div>
  </div>
</section>
