export function loginHelper(window: any, newQueryParams: any, clickUrl: string): void {
  const currentQueryParams = window.location.search;
  const currentSearchParams = new URLSearchParams(currentQueryParams);
  newQueryParams.forEach((value, key) => {
    currentSearchParams.append(key, value);
  });
  const newUrl = new URL(clickUrl);
  newUrl.search = currentSearchParams.toString();
  window.location.href = newUrl.toString();
}

export function crutchHelper(window: any, router: any): void {
  const searchParams = new URLSearchParams(window.location.search);
  const params: any = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  window.location.hostname.includes('client.') &&
  !window.location.href.includes('freepayment') &&
  !window.location.href.includes('bankid/callback')
    ? router.navigate(['/auth/login'], { queryParams: params })
    : null;
}
