import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type ConditionalOperatorProps = {
  trueCase?: (prevAnswer: any) => any | Observable<any>;
  falseCase?: (prevAnswer: any) => any | Observable<any>;
  condition?: (prevAnswer: any) => boolean | Observable<boolean>;
};

export function conditionalOperator({
  trueCase = () => null,
  falseCase = () => null,
  condition = (v) => v,
}: ConditionalOperatorProps): (source$: Observable<any>) => Observable<any> {
  let prev = {};
  return (source$) =>
    source$.pipe(
      switchMap((v: any) => {
        prev = v;
        const bool = condition(v);
        return bool instanceof Observable ? bool : of(bool);
      }),
      switchMap((value: boolean) => {
        const res = value ? trueCase(prev) : falseCase(prev);
        return res instanceof Observable ? res : of(res);
      }),
    );
}
