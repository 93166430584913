import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@libs/guards/auth.guard';
import { StageGuard } from '@libs/guards/stage.guard';
import * as Config from '@libs/configs/general-config';

const routes: Routes = [
  {
    path: Config.RouterConfig.auth,
    loadChildren: () => import('@libs/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Config.RouterConfig.form,
    loadChildren: () => import('@libs/modules/form/form.module').then((m) => m.FormModule),
    canActivate: [AuthGuard, StageGuard],
    data: {
      stage: ['ACCOUNT', 'UBKI_ID', 'PERSONAL_DATA', 'ADDRESS_DATA', 'EMPLOYMENT_DATA'],
      url: 'main',
    },
  },
  {
    path: Config.RouterConfig.system,
    loadChildren: () => import('@libs/modules/system-group/system-group.module').then((m) => m.SystemGroupModule),
    canActivate: [AuthGuard, StageGuard],
    data: {
      stage: ['CLIENT', 'CARD', 'APPLICATION', 'LOAN', 'FINISHED_LOAN'],
      url: 'form',
    },
  },
  {
    path: 'verification',
    loadChildren: () => import('@libs/modules/verification/verification.module').then((m) => m.VerificationModule),
    canActivate: [AuthGuard],
  },
  {
    path: Config.RouterConfig.bankid,
    loadChildren: () =>
      import('@libs/modules/verification//components/verification-callback/verification-callback.module').then(
        (m) => m.VerificationCallbackModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: Config.RouterConfig.main,
    loadChildren: () => import('@libs/modules/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard, StageGuard],
    data: {
      stage: ['CLIENT', 'CARD', 'APPLICATION', 'LOAN', 'FINISHED_LOAN'],
      url: 'form',
    },
  },
  {
    path: Config.RouterConfig.freepayment,
    loadChildren: () => import('@libs/modules/freepayment/freepayment.module').then((m) => m.FreepaymentModule),
  },
  {
    path: Config.RouterConfig.certificateOfClosure,
    loadChildren: () =>
      import('@libs/modules/certificate-of-closure/certificate-of-closure.module').then((m) => m.CertificateOfClosureModule),
  },
  {
    path: '**',
    redirectTo: Config.RouterConfig.auth,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
