import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ValidationHttpErrorResponse } from '../models/models';
import { ErrorHandlerService } from '../services/error-handler.service';
import { StatusCodes } from './status-codes.enum';
import { ToasterService } from '../shared/components/toaster-widget/toaster.service';
import { ToastType } from '../shared/components/toaster-widget/toaster-widget.component';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentType } from '../models/environment.type';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  static Error: {
    errorKey: string;
    errorData?: { [key: string]: any };
  };
  private ENV: EnvironmentType;

  constructor(
    private toaster: ToasterService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public errorHandlerService: ErrorHandlerService,
    @Inject('environment') private environment: EnvironmentType,
    private translateService: TranslateService,
  ) {
    this.ENV = this.environment;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          console.log('error.browser');
        }
        if (err.error instanceof Blob) {
          this.convertBlobError(err.error).subscribe((convertedErr) => {
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              message: convertedErr.message,
              loggerCode: convertedErr.loggerCode,
            });
          });
          this.sendError(err.message);
          return throwError(err);
        }

        switch (err.status) {
          case StatusCodes.CONNECTION_REFUSED:
            break;
          case StatusCodes.UNAUTHORIZED:
            break;
          case StatusCodes.BAD_REQUEST:
            const errors = (err.error as ValidationHttpErrorResponse).fieldErrors;
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              errors: errors,
            });
            this.sendError(err.message);
            return throwError(err);
          case StatusCodes.UNSUPPORTED_MEDIA:
            this.sendError(err.message);
            return throwError(err);
          case StatusCodes.UNPROCESSABLE_CONTENT:
            switch (err.error.message) {
              case 'error.inn.exists':
                HttpErrorInterceptor.Error = {
                  errorKey: err.error.message,
                  errorData: {
                    phone: err.error.additionalFields.phone,
                    message: 'error.inn.message',
                  },
                };
                this.toaster.showWithContent(ToastType.ERROR, {
                  title: `${this.translateService.instant(`errors.${err.error.message}`, {
                    phone: err.error.additionalFields.phone,
                  })}`,
                  message: 'error.inn.message',
                });
                break;
              default:
                this.toaster.showWithContent(ToastType.WARNING, {
                  title: 'errors.title',
                  message: err.error.message,
                });
                break;
            }
            this.sendError(err.message);
            return throwError(err);
          default:
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              message: err.error.message,
              loggerCode: err.error.loggerCode,
            });
            this.sendError(err.error.message);
            return throwError(err);
        }
      }),
    );
  }

  private sendError(error: any) {
    const stackLines = error.stack.split('\n');
    let errorLocation: string = '';
    if (stackLines.length > 1) {
      errorLocation = stackLines[1].trim();
    }
    const website = this.ENV.name;
    const token = sessionStorage.getItem('jwt_token');
    const phone = sessionStorage.getItem('phone');
    const url = window.location.pathname;
    if (this.ENV.production) {
      this.errorHandlerService.sendErrors('WARN', error, website, url, phone, token, errorLocation).subscribe(() => {});
    }
  }

  // this method may be not optimal (async error response converting), should come up with other decision
  private convertBlobError(blob: Blob): Observable<any> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const responseText = reader.result as string;
        const error = JSON.parse(responseText);
        observer.next(error);
        observer.complete();
      };
      reader.readAsText(blob, 'utf-8');
    });
  }
}
