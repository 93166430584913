import { Inject, Injectable } from '@angular/core';
import { EnvironmentType } from '../models/environment.type';

declare const grecaptcha: any;

@Injectable()
export class RecaptchaHelper {
  constructor(@Inject('environment') private environment: EnvironmentType) {}

  public getRecaptchaToken(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.enterprise.ready(async () => {
        try {
          const token = await grecaptcha.enterprise.execute(this.environment.recaptchaKEY_CODE, { action: action });
          resolve(token);
        } catch (error) {
          reject(error);
        }
      });
    });
  }
}
