import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VerificationType } from '../../../../enums/verification-type.enum';
import { GroupType } from '../../../../types/group.type';

@Component({
  selector: 'app-auth-repeat',
  templateUrl: './auth-repeat.component.html',
  styleUrls: ['./auth-repeat.component.sass'],
})
export class AuthRepeatComponent {
  @Input()
  public group: GroupType;

  @Input()
  public showPhone: boolean;

  @Input()
  public shortVal: boolean;

  @Input()
  public timeDown: boolean;

  @Output()
  public readonly wayVerif: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  public verificationType = VerificationType;

  public getWay(type: VerificationType): void {
    this.wayVerif.emit(type);
  }
}
