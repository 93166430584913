import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavItem } from '@libs/models/nav-item.interface';
import { IContact } from '../../../../../models/models';
import { EnvironmentType } from '../../../../../models/environment.type';
import { environment } from '../../../../../environment';

@Component({
  selector: 'app-header-mobile-navigation',
  templateUrl: `./templates/${environment.name}/page.html`,
  styleUrls: [`./templates/${environment.name}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileNavigationComponent {
  @Input()
  public items: NavItem[] = [];

  @Input()
  public active: boolean;

  @Input()
  public isMenu: boolean;

  @Input()
  public phone: IContact;

  @Input()
  public env: EnvironmentType;

  @Input()
  public isWebSite: boolean = false;

  @Input()
  public isLogged: boolean;

  @Output()
  public readonly logoutEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly itemClickEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly hamburgerEvent: EventEmitter<void> = new EventEmitter<void>();
}
