import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToasterComponentData, ToasterWidgetComponent, ToastType } from './toaster-widget.component';
import { IFormFieldError } from '../../../models/models';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private duration: number = 60 * 1000;
  private isSnackbarOpen: boolean = false;
  private notificationQueue: any[] = [];
  private snackBarRef: MatSnackBarRef<ToasterWidgetComponent>;

  constructor(private snackBar: MatSnackBar) {}

  private static get config(): MatSnackBarConfig {
    return {
      direction: 'ltr',
      horizontalPosition: 'center',
      verticalPosition: 'top',
    } as MatSnackBarConfig;
  }

  public success(title: string = '', className: string = '') {
    this.addToQueue(() => this.show(ToastType.SUCCESS, title, className));
  }

  public error(title: string = '', className: string = '') {
    this.addToQueue(() => this.show(ToastType.ERROR, title, className));
  }

  public warning(title: string = '', className: string = '') {
    this.addToQueue(() => this.show(ToastType.WARNING, title, className));
  }

  public info(title: string = '', className: string = '') {
    this.addToQueue(() => this.show(ToastType.INFO, title, className));
  }

  public close() {
    this.snackBar.dismiss();
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isSnackbarOpen = false;

      if (this.notificationQueue.length) {
        const nextNotification = this.notificationQueue.shift();
        nextNotification();
      }
    });
  }

  public showWithContent(
    type: ToastType,
    data: {
      title?: string;
      message?: string;
      loggerCode?: string;
      errors?: IFormFieldError[];
    },
  ) {
    this.addToQueue(() => {
      this.snackBarRef = this.snackBar.openFromComponent(ToasterWidgetComponent, {
        ...ToasterService.config,
        duration: this.duration,
        data: {
          type,
          title: data.title,
          content: {
            message: data.message,
            loggerCode: data.loggerCode,
            errors: data.errors,
          },
          onClose: () => this.close(),
        } as ToasterComponentData,
      });
    });
  }

  private show(type: ToastType, title: string, className: string = '') {
    this.isSnackbarOpen = true;

    this.snackBarRef = this.snackBar.openFromComponent(ToasterWidgetComponent, {
      ...ToasterService.config,
      duration: this.duration,
      panelClass: className,
      data: {
        type,
        title,
        onClose: () => this.close(),
      } as ToasterComponentData,
    });
  }

  private addToQueue(notificationFn: () => void) {
    if (this.isSnackbarOpen) {
      this.notificationQueue.push(notificationFn);
    } else {
      notificationFn();
    }
  }
}
