import { Injectable, Inject } from '@angular/core';
import { ErrorHandlerService } from '../services/error-handler.service';
import { EnvironmentType } from '../models/environment.type';
import { WINDOW } from '../shared/injections/window';

@Injectable()
export class SendEventHelper {
  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject('environment') private environment: EnvironmentType,
    public errorHandlerService: ErrorHandlerService,
  ) {}

  public send(phone: string | number, message): void {
    const website = this.environment.name;
    const url = this.window.location.pathname;
    if (this.environment.production) {
      this.errorHandlerService.sendErrors('WARN', message, website, url, phone).subscribe(() => {});
    }
  }
}
