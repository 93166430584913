import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClientModel } from '../models/client.model';
import { ClientStore } from '../shared/storage/client.store';
import * as Config from '../configs/general-config';

@Injectable()
export class FormGuard implements CanActivate, CanActivateChild {
  private path = Config.StaticDataConfig.redirectPathByClientStage;

  constructor(private router: Router, private clientStore: ClientStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.clientStore.getClient().map((client: ClientModel) => {
      if (route.data.stage.includes(client.stage)) {
        return true;
      } else {
        this.router.navigate(['form', this.path.form[client.stage]]);
        return false;
      }
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}
