import {Component, Inject, OnInit, Self} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {MetaService} from '../../services/meta.service';
import {distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import {UnsubscribeService} from '../../shared/services/unsubscribe.service';
import {UtmsEnum} from '../../enums/utms.enum';
import {EnvironmentType} from "../../models/environment.type";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass'],
  providers: [UnsubscribeService],
})
export class AuthComponent implements OnInit {
  private readonly IS_AUTHENTICATED: string = 'isAuthenticated';

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    @Inject('environment') private environment: EnvironmentType,
    @Self() private destroyStream$: UnsubscribeService,
  ) {
    this.metaService.createCanonicalURL();
    this.metaService.updateMetaTags('SEO.AUTH');
  }

  public ngOnInit(): void {
    if ((this.cookieService.get(this.IS_AUTHENTICATED) && window.location.pathname === '/auth/login') || window.location.pathname === '/') {
      this.router.navigate(['main', 'loan']);
    }

    this.initCookiesParams();
  }

  private initCookiesParams(): void {
    this.route.queryParamMap
      .pipe(
        map((params: Params) => params.params),
        distinctUntilChanged(),
        map((params: Params) => ({
          params: params,
          value: Object.keys(params).some((key: string) => Object.values(UtmsEnum).some((v) => v === key)),
        })),
        tap((data: { params: Params; value: boolean }) => {
          if (data.value) {
            this.cookieService.deleteAll('/', this.environment.cookieDomain);
            Object.values(UtmsEnum).forEach((key: string) => {
              this.cookieService.set(key, data.params[key] ?? null, 30, '/', this.environment.cookieDomain);
            });
          }
        }),
        takeUntil(this.destroyStream$),
      )
      .subscribe();
  }
}
