import { Inject, Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentType } from '../../models/environment.type';

@Injectable()
export class GuidService {
  readonly GUID: string = 'guid';
  readonly ZE_GUID: string = 'ze_guid';
  private storage: Storage = localStorage;

  constructor(private cookieService: CookieService, @Inject('environment') private environment: EnvironmentType) {}

  public getGuid(): string {
    const cookieGuid = this.cookieService.get(this.ZE_GUID);
    if (cookieGuid && cookieGuid !== 'undefined') {
      return cookieGuid;
    }
    const storageGuid = this.storage.getItem(this.GUID);
    const zeGuid = !storageGuid || storageGuid === 'undefined' ? uuid() : storageGuid;
    this.cookieService.set(this.ZE_GUID, zeGuid, 3650, '/', this.environment.cookieDomain);
    this.storage.setItem(this.GUID, zeGuid);
    return zeGuid;
  }
}
