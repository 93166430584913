import * as Models from '../../../../../libs/models/models';

export class ContactConfig {
  static phones: Array<Models.IContact> = [
    {
      label: '0 800 202 233',
      contact: 'tel:0800202233',
    },
    {
      label: '+38(063) 020 22 33',
      contact: 'tel:+380630202233',
    },
  ];

  static emails: Array<Models.IContact> = [
    {
      label: 'mailto:info@starfin.com.ua',
      contact: 'info@starfin.com.ua',
    },
  ];
}
