export enum LoanPurposeEnum {
  HOUSEHOLD_APPLIANCES = 'Купівля побутової техніки/інструментів/меблів',
  HOME_REPAIR = 'Ремонт оселі/автотранспорту',
  TUITION = 'Оплата навчання',
  UTILITIES = 'Оплата комунальних послуг',
  MEDICAL = 'Лікування',
  GROCERIES = 'Купівля продуктів харчування',
  CLOTHING = 'Купівля одягу/взуття/домашнього текстилю',
  ENTERTAINMENT = 'Розваги/відпочинок',
  GAMBLING = 'Гральна індустрія (казино)',
  REPAYMENT = 'Погашення інших кредитів',
}
