import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { GroupType } from '../../../types/group.type';

@Component({
  selector: 'app-counter-widget[initialState]',
  templateUrl: './counter-widget.component.html',
  styleUrls: ['./counter-widget.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterWidgetComponent implements OnInit, OnDestroy {
  public currentCount: string;

  public progress: number = 0;

  public restartTimer = new Subject<void>();

  public subscription: Subscription;

  @Input()
  public initialState: {
    count: number;
    breakpoint?: number;
    diameter?: number;
    strokeWidth?: number;
  } = {
    count: 60,
    breakpoint: 10,
    diameter: 90,
    strokeWidth: 5,
  };

  @Input()
  public group: GroupType = 'AVANS';

  @Input()
  public type: 'TIMER' | 'COUNTDOWN' = 'TIMER';

  @Output()
  public readonly breakpointOutEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly timeOutEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly tickEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(private changeDetector: ChangeDetectorRef, private cookieService: CookieService) {}

  public ngOnInit(): void {
    this.initTimer();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public initTimer(): void {
    this.type === 'COUNTDOWN' ? this.countdownTimer() : this.verificationTimer();
  }

  private verificationTimer(): void {
    this.currentCount = this.formatValue(this.initialState.count);

    let time = this.initialState.count;
    const tick: Observable<number> = interval(1000);
    this.subscription = tick
      .pipe(
        takeUntil(this.restartTimer),
        take(this.initialState.count),
        tap((tick: number) => {
          this.tickEvent.emit(tick);
          if (tick === this.initialState?.breakpoint) {
            this.breakpointOutEvent.emit(true);
          }
        }),
        map(() => --time),
      )
      .subscribe(
        (value: number) => {
          this.setCountTimer(value);
          this.currentCount = this.formatValue(value);
          this.changeDetector.detectChanges();
        },
        (error) => console.error(error),
        () => {
          this.timeOutEvent.emit(true);
          this.subscription.unsubscribe();
        },
      );
  }

  private countdownTimer(): void {
    this.currentCount = this.formatValue(this.initialState.count);
    this.initialState.count = this.initialState.count * 10;

    let time = this.initialState.count;
    const tick: Observable<number> = interval(100);
    this.subscription = tick
      .pipe(
        takeUntil(this.restartTimer),
        take(this.initialState.count),
        tap((tick: number) => {
          this.tickEvent.emit(tick);
          if (tick / 10 === this.initialState?.breakpoint) {
            this.breakpointOutEvent.emit(true);
          }
        }),
        map(() => --this.initialState.count),
      )
      .subscribe(
        (value: number) => {
          this.currentCount = this.formatValue(Math.round(value / 10));
          this.progress = 100 - (this.initialState.count / time) * 100;
          this.changeDetector.detectChanges();
        },
        (error) => console.error(error),
        () => {
          this.timeOutEvent.emit(true);
          this.subscription.unsubscribe();
        },
      );
  }

  private formatValue(value): string {
    const minutes = Math.floor(value / 60);
    const formattedMinutes = '' + (minutes > 9 ? minutes : '0' + minutes);
    const seconds = value % 60;
    const formattedSeconds = '' + (seconds > 9 ? seconds : '0' + seconds);

    switch (this.group) {
      case 'AVANS':
        return `(${value})`;
      case 'STARFIN':
        return `- ${value}с`;
      case 'SUNCREDIT':
        return `${formattedMinutes}:${formattedSeconds}с`;
      default:
        return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  private setCountTimer(value: number): void {
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + value);
    this.cookieService.set('count-timer', value.toString(), expirationDate, '/');
  }
}
