<section [class.auth_indent]="isPreview$ | async" [ngClass]="'auth-' + type" class="auth">
  <div class="auth-content">
    <app-auth-phone
      #phoneCom
      (emitData)="setValues($event)"
      (emitPhone)="setPhone($event)"
      *ngIf="!isAuthConfirm && !isResend && !isAuthCallConfirm"
      [group]="group"
      [production]="production"
    ></app-auth-phone>

    <app-auth-auto-confirm
      (editPhoneEvent)="editPhoneHandler()"
      (redirectClientEvent)="redirectClientHandler($event)"
      (showPhoneEditorEvent)="showPhoneEditorModal()"
      (timeGoneEvent)="timeGoneHandler()"
      (verifyWayEvent)="chooseWayRepeat($event)"
      *ngIf="isAuthCallConfirm"
      [group]="group"
      [initialState]="initialState"
      [phone]="phone"
      [redirectDone]="redirectDone"
      [showPhone]="showPhone"
      [withCaptcha]="withCaptcha"
    ></app-auth-auto-confirm>

    <app-auth-confirm
      (editPhoneEvent)="editPhoneHandler()"
      (redirectClientEvent)="redirectClientHandler($event)"
      (showPhoneEditorEvent)="showPhoneEditorModal()"
      (timeGoneEvent)="timeGoneHandler()"
      (verifyWayEvent)="chooseWayRepeat($event)"
      *ngIf="isAuthConfirm"
      [group]="group"
      [phone]="phone"
      [showPhone]="showPhone"
      [smsViber]="smsViber"
      [verificationType]="verificationType"
    ></app-auth-confirm>

    <app-auth-repeat (wayVerif)="chooseWayRepeat($event)" *ngIf="isResend" [group]="group" [showPhone]="showPhone"></app-auth-repeat>
  </div>
</section>
