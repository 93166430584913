import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToasterService } from './toaster.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterWidgetComponent } from './toaster-widget.component';

@NgModule({
  declarations: [ToasterWidgetComponent],
  imports: [CommonModule, TranslateModule, MatSnackBarModule],
  exports: [ToasterWidgetComponent, MatSnackBarModule],
  providers: [ToasterService],
})
export class ToasterWidgetModule {}
