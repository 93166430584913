<div [ngClass]="dialogClasses">
  <span (click)="closeDialog()" *ngIf="showCloseBtn" [mat-dialog-close]="true" class="dialog-close-btn" mat-icon-button tabindex="-1">
  </span>

  <div class="dialog-content">
    <ng-content select="[dialogContent]"></ng-content>
  </div>

  <div class="dialog-actions">
    <ng-content select="[dialogActions]"></ng-content>
  </div>
</div>
