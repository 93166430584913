import { Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentType } from '../../../models/environment.type';

@Component({
  selector: 'app-recaptcha',
  template: '',
})
export class RecaptchaComponent implements OnDestroy {
  constructor(@Inject(DOCUMENT) private document: Document, @Inject('environment') private environment: EnvironmentType) {
    if (this.environment.production) this.initRecaptcha();
  }

  ngOnDestroy(): void {
    const arr: string[] = ['#recaptchaInit'];
    arr.forEach((id: string) => {
      this.document.querySelector(id).remove();
    });
  }

  private initRecaptcha(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.environment.recaptchaKEY_CODE}`;
    script.type = 'text/javascript';
    script.id = 'recaptchaInit';
    this.document.head.appendChild(script);
  }
}
