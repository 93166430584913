<nav *ngIf="isMobile; else desktop" class="navigation">
  <div class="navigation-mobile d-flex align-items-center">
    <app-header-mobile-navigation
      (hamburgerEvent)="hamburgerEvent.emit()"
      (itemClickEvent)="itemEventHandler()"
      (logoutEvent)="logoutHandler()"
      [active]="active"
      [env]="env"
      [isLogged]="isLogged"
      [isMenu]="isMenu"
      [isWebSite]="isWebSite"
      [items]="navItems"
      [phone]="phone"
    ></app-header-mobile-navigation>
    <div (click)="hamburgerEvent.emit()" [ngClass]="{ hamburger_active: active }" class="hamburger">
      <span class="hamburger__line"></span>
    </div>
  </div>
</nav>

<ng-template #desktop>
  <div *ngIf="isMenu" class="navigation-desktop d-flex align-items-end">
    <app-header-desktop-navigation [items]="navItems"></app-header-desktop-navigation>
  </div>
</ng-template>
