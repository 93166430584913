import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, Validators } from '@angular/forms';
import { IControlWithAnyValue, IControlWithValidatorsFn } from '../models/models';
import { TrackingService } from './tracking.service';
import { InputName } from '../models/tracking.model';

@Injectable({
  providedIn: 'root',
})
export class FormExecutorService {
  constructor(private trackingService: TrackingService) {}

  public fillForm(form: FormGroup, data): void {
    Object.keys(data).forEach((key) => {
      if (form.controls[key]) {
        moment(data[key], 'YYYY-MM-DD', true).isValid()
          ? form.controls[key].setValue(moment(data[key]).format('DD/MM/YYYY'))
          : form.controls[key].setValue(data[key]);
      }
    });
  }

  public updateValueAndValidityControls(form: FormGroup, ...controls: string[]): void {
    const customControls = controls.length !== 0 ? controls : Object.keys(form.controls);
    customControls.forEach((c) => form.controls[c].updateValueAndValidity());
  }

  public clearValidatorsControls(form: FormGroup, ...controls: string[]): void {
    const customControls = controls.length !== 0 ? controls : Object.keys(form.controls);
    customControls.forEach((c) => form.controls[c].clearValidators());
  }

  public setValidatorsControls(form: FormGroup, ...controlsWithFn: IControlWithValidatorsFn[]): void {
    controlsWithFn.forEach((controlWithFn) => {
      for (const controlKey in controlWithFn) {
        if (controlWithFn.hasOwnProperty(controlKey)) {
          form.controls[controlKey].setValidators(Validators.compose([...controlWithFn[controlKey]]));
        }
      }
    });
  }

  public setValuesForm(form: FormGroup, ...controls: IControlWithAnyValue[]): void {
    controls.forEach((controlWithValue) => {
      for (const control in controlWithValue) {
        if (controlWithValue.hasOwnProperty(control)) {
          form.controls[control].setValue(controlWithValue[control]);
        }
      }
    });
  }

  public inputChangeEvent(fieldName: InputName, event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    if (
      (event.keyCode >= 65 && event.keyCode <= 90) ||
      (event.keyCode >= 97 && event.keyCode <= 122) ||
      (event.keyCode >= 48 && event.keyCode <= 57)
    ) {
      // Numbers or letters
      this.trackingService.addAction(fieldName, 'manual-fill', value);
    } else if (event.keyCode == 8 || event.keyCode == 46) {
      // Delete or backspace
      this.trackingService.addAction(fieldName, 'backspace', value);
    }
  }

  public inputPasteEvent(fieldName: InputName, event: ClipboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const text = value + event!.clipboardData!.getData('text');
    this.trackingService.addAction(fieldName, 'paste', text);
  }

  public keyUpEvent(fieldName: InputName, event: KeyboardEvent): void {
    if (
      (event.keyCode >= 65 && event.keyCode <= 90) ||
      (event.keyCode >= 97 && event.keyCode <= 122) ||
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      event.keyCode == 46 ||
      event.keyCode == 8
    ) {
      const value = (event.target as HTMLInputElement).value;
      this.trackingService.setLastActionEnd(fieldName, value);
    }
  }

  public inputBlurEvent(fieldName: InputName, event: FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.trackingService.setBlured(fieldName, value);
  }
}
