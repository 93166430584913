import { AfterViewInit, Component, EventEmitter, Inject, INJECTOR, Injector, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { VerificationActionType } from '../../../enums/verification-action-type.enum';
import { VerificationType } from '../../../enums/verification-type.enum';

@Component({
  selector: 'app-sms-code-widget',
  templateUrl: './sms-code-widget.component.html',
  styleUrls: ['./sms-code-widget.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SmsCodeWidgetComponent,
      multi: true,
    },
  ],
})
export class SmsCodeWidgetComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public type: 'base' | 'avans' | 'starfin' | 'suncredit' = 'base';

  @Input()
  public verificationAction: VerificationActionType;

  @Input()
  public verificationType: VerificationType;

  @Input()
  public classes: string = '';

  @Input()
  public placeholder: string = 'Placeholder';

  @Input()
  public value: string | number | any = '';

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public smsViber: boolean = false;

  @Output()
  public readonly clickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public readonly changeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public readonly focusEvent: EventEmitter<any> = new EventEmitter<any>();
  public hasFocus: boolean = false;
  public control: AbstractControl | null;

  constructor(@Inject(INJECTOR) private injector: Injector) {}

  public get inputClasses(): string {
    return `${this.classes}`;
  }

  public ngAfterViewInit(): void {
    this.getControl();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnInput(fn: any): void {
    this.onInput = fn;
    this.changeEvent.emit(fn.target.value);
  }

  public registerFocusEvent(event: any): void {
    this.hasFocus = true;
    this.focusEvent.emit(event);
  }

  public registerClickEvent(event: any): void {
    this.clickEvent.emit(event);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public input($event: Event): void {
    this.value = ($event.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  public change($event: Event): any {
    this.value = ($event.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  public markAsTouched(): void {
    this.hasFocus = false;
    if (this.control?.invalid) {
      this.control?.markAsTouched();
    }
  }

  private getControl(): void {
    setTimeout(() => {
      this.control = this.injector.get(NgControl)?.control;
    });
  }

  private onChange: any = () => {};

  private onInput: any = () => {};

  private onTouched: any = () => {};
}
