<section class="auth-confirm d-flex flex-column align-items-center justify-content-start">
  <div class="auth-confirm-content">
    <h2 class="auth-confirm-title text-center">{{ 'AUTH.TITLE' | translate }}</h2>
    <p *ngIf="verificationType === 'SMS'" class="auth-confirm-text mb-2">
      <span [innerHTML]="'CONFIRM.INFO_1' | translate : { number: phoneNumber }"></span>
      <span (click)="showPhoneEditorEvent.emit()" class="pl-1 cursor-pointer font-weight-bold">{{ 'AUTH.EDIT' | translate }}</span>
      <span *ngIf="!smsViber" [innerHTML]="'CONFIRM.INFO_2' | translate" class="d-inline-block"></span>
      <span *ngIf="smsViber" [innerHTML]="'CONFIRM.INFO_2_VIBER' | translate" class="d-inline-block"></span>
    </p>
    <p *ngIf="verificationType === 'PHONE'" class="auth-confirm-text mb-3">
      <span
        [innerHTML]="
              'CONFIRM.INFO_PHONE_1' | translate : { number: phoneNumber }
            "
      ></span>
      <span (click)="showPhoneEditorEvent.emit()" class="pl-1 cursor-pointer font-weight-bold">{{ 'AUTH.EDIT' | translate }}</span>
      <span [innerHTML]="'CONFIRM.INFO_PHONE_2' | translate" class="d-inline-block mt-3"></span>
    </p>
  </div>
  <div [formGroup]="form" class="auth-confirm-footer">
    <div class="mb-3">
      <app-sms-code-widget
        [placeholder]="''"
        [smsViber]="smsViber"
        [type]="'starfin'"
        [verificationAction]="loginAction"
        [verificationType]="verificationType"
        classes="text-center"
        formControlName="code"
      ></app-sms-code-widget>
      <div [class.d-none]="timeOut" class="mt-4">
        <app-countdown-container
          (breakpointOutEvent)="breakpointOutHandler($event)"
          (timeOutEvent)="timeOutEventHandler($event)"
          [counterType]="'TIMER'"
          [group]="group"
          [initialState]="initialState"
          [showProgressBar]="true"
          [verificationType]="verificationType"
          classes="countdown-starfin"
        ></app-countdown-container>
      </div>
    </div>
    <ng-container *ngIf="breakpointTimeOut">
      <app-resend-sms-widget
        (verifyWayEvent)="wayVerificationHandler($event)"
        [group]="group"
        [showAuthMessage]="true"
        [showPhone]="showPhone"
        [smsViber]="smsViber"
        [timeOut]="timeOut"
      ></app-resend-sms-widget>
    </ng-container>
    <div class="auth-agreement mt-3">
      <app-auth-agreement formGroupName="agreement" labelClasses="fs-12 text-left"></app-auth-agreement>
    </div>
  </div>
</section>
