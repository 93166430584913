<header [class.mb-64]="!(isPreview$ | async) && isStepsVisible" [class.mb-96]="(isPreview$ | async) && isStepsVisible" class="header">
  <div class="header-wrap">
    <div class="header-inner d-flex align-items-center justify-content-between">
      <div [ngClass]="{ active: hamburger }" class="header-logo">
        <img [src]="env.LOGO_SITE" alt="" class="header-logo-icon" height="100%" width="100%" />
      </div>

      <div class="header-content d-flex justify-content-end">
        <ng-container *ngIf="isLogged$ | async">
          <app-header-navigation
            (hamburgerEvent)="hamburgerEventHandler()"
            (logoutEvent)="logoutHandler()"
            [active]="hamburger"
            [env]="env"
            [isLogged]="isLogged$ | async"
            [isMenu]="isMenu$ | async"
            [isMobile]="tabletWide$ | async"
            [navItems]="navItems"
            [phone]="phone"
          ></app-header-navigation>
        </ng-container>

        <div class="header-controls">
          <div class="phone">
            <a [href]="[phone.contact]" class="phone-link">{{ phone.label }}</a>
          </div>

          <ng-container *ngIf="isLogged$ | async">
            <div (click)="logoutHandler()" class="logout cursor-pointer ml-3 d-flex align-items-center" mat-mini-fab>
              <svg class="logout-icon" fill="none" height="20" viewBox="0 0 22 22" width="20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.2998 21.9998C14.5916 21.9998 14.8714 21.8839 15.0777 21.6776C15.2839 21.4713 15.3998 21.1915 15.3998 20.8998C15.3998 20.608 15.2839 20.3282 15.0777 20.122C14.8714 19.9157 14.5916 19.7998 14.2998 19.7998H5.13328C4.35532 19.7998 3.60922 19.4907 3.05912 18.9406C2.50902 18.3905 2.19998 17.6444 2.19998 16.8665V5.13328C2.19998 4.35532 2.50902 3.60922 3.05912 3.05912C3.60922 2.50902 4.35532 2.19998 5.13328 2.19998H14.2998C14.5916 2.19998 14.8714 2.08408 15.0777 1.8778C15.2839 1.67151 15.3998 1.39172 15.3998 1.09999C15.3998 0.808253 15.2839 0.528467 15.0777 0.322179C14.8714 0.115891 14.5916 0 14.2998 0H5.13328C3.77185 0 2.46618 0.540826 1.5035 1.5035C0.540826 2.46618 0 3.77185 0 5.13328V16.8665C0 18.2279 0.540826 19.5336 1.5035 20.4963C2.46618 21.4589 3.77185 21.9998 5.13328 21.9998H14.2998ZM15.7533 5.42514C15.8594 5.32708 15.9838 5.25089 16.1194 5.20092C16.2549 5.15094 16.399 5.12817 16.5434 5.13389C16.6877 5.13961 16.8296 5.17371 16.9608 5.23426C17.0919 5.2948 17.2099 5.38059 17.3079 5.48674L21.7079 10.2534C21.8957 10.4566 22 10.7232 22 10.9999C22 11.2766 21.8957 11.5432 21.7079 11.7464L17.3079 16.513C17.1098 16.7271 16.8346 16.8538 16.5431 16.8651C16.2515 16.8763 15.9674 16.7713 15.7533 16.5731C15.5392 16.375 15.4125 16.0998 15.4012 15.8083C15.39 15.5167 15.495 15.2326 15.6932 15.0185L18.3889 12.0984H6.96659C6.67485 12.0984 6.39507 11.9825 6.18878 11.7762C5.98249 11.5699 5.8666 11.2901 5.8666 10.9984C5.8666 10.7067 5.98249 10.4269 6.18878 10.2206C6.39507 10.0143 6.67485 9.89842 6.96659 9.89842H18.3874L15.6917 6.97832C15.5936 6.87221 15.5174 6.74782 15.4675 6.61225C15.4175 6.47669 15.3947 6.33261 15.4004 6.18824C15.4062 6.04387 15.4403 5.90204 15.5008 5.77085C15.5614 5.63967 15.6471 5.52169 15.7533 5.42367V5.42514Z"
                  fill="#3C938B"
                />
              </svg>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="header-footer">
    <ng-container *ngIf="(isPreview$ | async) && (isLogged$ | async)">
      <app-loan-preview [group]="env.group" [isLogged]="isLogged$ | async" [preview]="preview$ | async" [type]="'small'"></app-loan-preview>
    </ng-container>
    <app-stepper-widget (onVisibleChange)="onStepsVisibleChange($event)" [steps]="steps" [type]="env.group"></app-stepper-widget>
  </div>
</header>
