import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { Observable } from 'rxjs';

@Injectable()
export class ScannerService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  postPhoto(photoData: FormData, loanId: number): Observable<any> {
    return this.http.post(`${this.urlService.hostUrl}/loans/${loanId}/photos`, photoData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  postSelfie(photoData: FormData): Observable<any> {
    return this.http.post(this.urlService.selfieUrl, photoData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  postFile(fileName: FormData): Observable<any> {
    return this.http.post(`${this.urlService.uploadDocumentUrl}/`, fileName, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getFile(): Observable<any> {
    return this.http.get(`${this.urlService.uploadDocumentUrl}/`);
  }

  confirmFile(): Observable<any> {
    return this.http.post(`${this.urlService.uploadDocumentUrl}/?confirm`, {});
  }

  deleteFile(documentId: number): Observable<any> {
    return this.http.delete(`${this.urlService.uploadDocumentUrl}/${documentId}`, {});
  }

  getPhoto(): Observable<any> {
    return this.http.get<any>(this.urlService.scannerUrl);
  }
}
