import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { ClientModel } from '../models/client.model';
import { TokenResponse } from '../models/token-response.model';
import { SendEventHelper } from '../helpers/send-event.helper';

@Injectable()
export class ClientService {
  constructor(private http: HttpClient, private urlService: UrlService, private eventHelper: SendEventHelper) {}

  getClientInfo(): Observable<any> {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'started get /me');
    return this.http.get(this.urlService.profileUrl);
  }

  getClientIdentOptions(): Observable<any> {
    return this.http.get(this.urlService.identOptionsUrl);
  }

  updateClientInfo(data: any, path: string): Observable<ClientModel> {
    return this.http.post<ClientModel>(`${this.urlService.profileUrl}${path}`, data);
  }

  updateFinanceNumber(data: any): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(this.urlService.financeNumberUrl, data);
  }

  testUpdateFinanceNumber(data: any): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(this.urlService.financeNumberTestUrl, data);
  }

  updateUbkiIdData(): Observable<any> {
    return this.http.post(this.urlService.updateUbkiId, {});
  }

  addPhotoForRevision(image: Blob, loanId: number, isSelfie: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('isLivePhoto', `${isSelfie}`);
    return this.http.post(`${this.urlService.loanContactUrl}/${loanId}${this.urlService.documentsRevisionUrl}`, formData);
  }

  confirmAllPhotosRevision(loanId: number): Observable<any> {
    return this.http.post(`${this.urlService.loanContactUrl}/${loanId}${this.urlService.documentsRevisionUrl}?confirm`, {});
  }
}
