<div [ngClass]="classes" class="d-flex flex-column align-items-center justify-content-between">
  <div *ngIf="data.title" [innerHTML]="data.title | translate" class="toaster-title text-center"></div>
  <div *ngIf="data.content?.message" [translate]="'errors.' + data.content?.message" class="toaster-content"></div>
  <div
    *ngIf="data.content?.loggerCode"
    [innerHTML]="'errors.unexpectedError' | translate : { code: data.content?.loggerCode }"
    class="toaster-content"
  ></div>
  <div *ngIf="data.content?.errors?.length" class="toaster-content">
    <ng-container *ngFor="let err of data.content?.errors">
      <p class="toaster-error__text">
        <span [translate]="'client.' + err.field"></span>:
        <span [translate]="err.message" class="pl-1"></span>
      </p>
    </ng-container>
  </div>
  <img (click)="close()" alt="close icon" class="toaster-close" src="/assets/img/x-close.svg" />
</div>
