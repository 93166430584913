<div class="auth-repeat">
  <div class="auth-landscape">
    <div class="auth-landscape__body">
      <div *ngIf="!shortVal" class="auth-landscape-content">
        <h2 class="auth-landscape__title">{{ 'AUTH.TITLE' | translate }}</h2>
        <p class="auth-landscape__text">{{ 'CONFIRM.PHONE' | translate }}</p>
      </div>
      <div *ngIf="shortVal" class="auth-landscape-content">
        <p *ngIf="!timeDown && group !== 'AVANS' && showPhone" [innerHtml]="'AUTH.SHORT_TEXT' | translate" class="auth-landscape__text"></p>
        <p *ngIf="timeDown || group === 'AVANS' || !showPhone" class="auth-landscape__text">
          {{ 'CONFIRM.PHONE' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="auth-repeat-footer">
    <app-buttons-way-code (wayRepeat)="getWay($event)" [group]="group" [showPhone]="showPhone"></app-buttons-way-code>
  </div>
</div>
