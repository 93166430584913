import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ResendSmsWidgetComponent } from './resend-sms-widget.component';

@NgModule({
  declarations: [ResendSmsWidgetComponent],
  imports: [CommonModule, TranslateModule, ButtonModule],
  exports: [ResendSmsWidgetComponent],
})
export class ResendSmsWidgetModule {}
