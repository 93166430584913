import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { TranslateLoaderFactory } from '../helpers/translate-loader-factory';
import { ImageService } from './services/image.service';

const MAT_MODULES = [MatDialogModule];

@NgModule({
  declarations: [],
  imports: [
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ...MAT_MODULES,
  ],
  providers: [ImageService],
  exports: [NgxMaskModule, TranslateModule, ...MAT_MODULES],
})
export class SharedModule {}
