import { InjectionToken, inject } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window', {
  providedIn: 'root',
  factory: () => window,
});

export const NAVIGATOR = new InjectionToken<Navigator>('navigator', {
  providedIn: 'root',
  factory: () => inject(WINDOW)?.navigator,
});
