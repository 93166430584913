import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  public sendErrors(
    type: string,
    errors: any,
    website: string,
    url: string,
    phone?: string | number,
    token?: string,
    errorLocation?: string,
  ) {
    if (errors.error) {
      errors = errors.error.message;
    }
    const phoneParam = phone ? `&phone=${phone}` : '';
    const tokenParam = token ? `&token=${token}` : '';
    const errorLocationParam = errorLocation ? `&errorLocation=${errorLocation}` : '';
    return this.http.post(
      `${this.urlService.loggerUrl}/${type}?website=${website}${phoneParam}${tokenParam}&message=${errors}&url=${url}${errorLocationParam}`,
      {},
    );
  }
}
