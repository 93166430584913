<section [formGroup]="form" class="auth-confirm">
  <div class="auth-confirm-content">
    <h2 class="auth-confirm-title text-center">{{ 'AUTH.TITLE' | translate }}</h2>
    <p class="auth-confirm-text mb-2">
      <span [innerHTML]="'AUTO_CONFIRM.CALL' | translate : { number: phoneNumber } "></span>
      <span (click)="showPhoneEditorEvent.emit()" class="cursor-pointer font-weight-bold">{{ 'AUTH.EDIT' | translate }}</span>
    </p>
    <p [innerHTML]="'AUTO_CONFIRM.CLICK' | translate" class="auth-confirm-text mb-3"></p>
    <ng-container [class.d-none]="timeOut">
      <app-countdown-container
        (breakpointOutEvent)="breakpointOutHandler($event)"
        (timeOutEvent)="timeOutEventHandler($event)"
        [counterType]="'TIMER'"
        [group]="group"
        [initialState]="initialState"
        [showProgressBar]="true"
        [verificationType]="verificationType"
        classes="countdown-starfin mt-2"
      ></app-countdown-container>
    </ng-container>
    <ng-container *ngIf="breakpointTimeOut">
      <app-resend-sms-widget
        (verifyWayEvent)="wayVerificationHandler($event)"
        [group]="group"
        [showAuthMessage]="true"
        [showPhone]="showPhone"
        [timeOut]="timeOut"
      ></app-resend-sms-widget>
    </ng-container>
    <div class="auth-agreement mt-3">
      <app-auth-agreement formGroupName="agreement" labelClasses="fs-12 text-left"></app-auth-agreement>
    </div>
  </div>
</section>
