import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { WINDOW } from '../shared/injections/window';

export enum Screens {
  MOBILE = 640,
  TABLE = 768,
  TABLE_WIDE = 992,
  MIN_DESKTOP = 1024,
  DESKTOP = 1280,
}

@Injectable()
export class DeviceService {
  private $deviceWidth = new BehaviorSubject<number>(0);
  private isMobile$ = new BehaviorSubject<boolean>(false);
  private isTablet$ = new BehaviorSubject<boolean>(false);
  private isTabletWide$ = new BehaviorSubject<boolean>(false);
  private isDesktop$ = new BehaviorSubject<boolean>(false);
  private $resizeObservable: Observable<Event>;

  constructor(@Inject(WINDOW) private readonly window: Window) {
    this.windowInit();
  }

  get width(): number {
    return this.$deviceWidth.value;
  }

  get isMobile(): Observable<boolean> {
    return this.isMobile$.asObservable();
  }

  get isTablet(): Observable<boolean> {
    return this.isTablet$.asObservable();
  }

  get isTabletWide(): Observable<boolean> {
    return this.isTabletWide$.asObservable();
  }

  get isDesktop(): Observable<boolean> {
    return this.isDesktop$.asObservable();
  }

  public getDeviceResize(): Observable<Event> {
    return this.$resizeObservable;
  }

  private windowInit(): void {
    this.$deviceWidth.next(this.window.innerWidth);
    this.isMobile$.next(this.window.innerWidth <= Screens.TABLE_WIDE);
    this.isTablet$.next(this.window.innerWidth > Screens.TABLE && window.innerWidth < Screens.MIN_DESKTOP);
    this.isTabletWide$.next(this.window.innerWidth < Screens.TABLE_WIDE && window.innerWidth < Screens.MIN_DESKTOP);
    this.isDesktop$.next(this.window.innerWidth >= Screens.MIN_DESKTOP);

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        tap(() => {
          const widthAfterResize = this.window.innerWidth;
          this.$deviceWidth.next(widthAfterResize);
          this.isMobile$.next(widthAfterResize < Screens.TABLE);
          this.isTablet$.next(widthAfterResize > Screens.TABLE && widthAfterResize < Screens.MIN_DESKTOP);
          this.isTabletWide$.next(widthAfterResize < Screens.TABLE_WIDE && widthAfterResize < Screens.MIN_DESKTOP);
          this.isDesktop$.next(widthAfterResize > Screens.MIN_DESKTOP);
        }),
      )
      .subscribe();
  }
}
