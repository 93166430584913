import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataForm } from '../../../../shared/static-data/Data-form.static';
import { VerificationType } from '../../../../enums/verification-type.enum';
import { UserVerificationService } from '../../../../services/user-verification.service';
import { AffiliateService } from '../../../../shared/services/affiliate.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { VerificationActionType } from '../../../../enums/verification-action-type.enum';
import { switchMap, takeUntil } from 'rxjs/operators';
import * as Models from '../../../../models/models';
import * as Config from '../../../../configs/general-config';
import { GroupType } from '../../../../types/group.type';
import { EnvironmentType } from '../../../../models/environment.type';
import { WINDOW } from '../../../../shared/injections/window';
import { environment } from '../../../../environment';
import { SendEventHelper } from '../../../../helpers/send-event.helper';
import { RecaptchaHelper } from '../../../../helpers/recaptcha.helper';
import { from } from 'rxjs';

@Component({
  selector: 'app-auth-phone',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: ['./auth-phone.component.sass', `./templates/${environment.group}/style.sass`],
  providers: [UnsubscribeService],
})
export class AuthPhoneComponent implements OnInit {
  @Input()
  public production: boolean;

  @Input()
  public group: GroupType;

  @Output()
  public readonly emitData: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public readonly emitPhone: EventEmitter<string> = new EventEmitter<string>();

  public verificationType: VerificationType = VerificationType.PHONE;
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isAuthConfirm: boolean = false;
  public isAuthCallConfirm: boolean = false;
  public isResend: boolean = false;
  public withCaptcha: boolean = false;
  public phone: string;
  public isDisabled: boolean = false;
  public smsViber: boolean = false;
  public doubleCountryRegular = Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_COUNTRY;
  public doubleZeroRegular = Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_ZERO;
  public defaultMobilePhone: number = 380;
  @ViewChild('phoneInput') phoneInput: any;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject('environment') private environment: EnvironmentType,
    public verificationService: UserVerificationService,
    public affiliateService: AffiliateService,
    @Self() private destroyStream$: UnsubscribeService,
    public route: ActivatedRoute,
    private eventHelper: SendEventHelper,
    private recaptchaHelper: RecaptchaHelper,
  ) {}

  get f(): Models.IFormControls {
    return this.loginForm.controls;
  }

  public ngOnInit(): void {
    this.activateForm();
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const input = this.phoneInput.nativeElement;
    let currentValue = input.value;
    currentValue = event.clipboardData.getData('text');
    switch (currentValue.length) {
      case 12:
        this.loginForm.get('phone').setValue(currentValue);
        break;
      case 11:
        this.loginForm.get('phone').setValue(3 + currentValue);
        break;
      case 10:
        this.loginForm.get('phone').setValue(38 + currentValue);
        break;
      case 9:
        this.loginForm.get('phone').setValue(380 + currentValue);
        break;
    }
  }

  public preventDefaultValueDeletion(event: any): boolean {
    if (
      (event.target.value.length === 4 || this.loginForm.get('phone').value === this.defaultMobilePhone) &&
      (event.code === 'Backspace' || event.code === 'Delete')
    ) {
      return false;
    }
    this.inputPhoneNumber(event);
    return true;
  }

  public inputPhoneNumber(event: any): void {
    if (
      this.doubleCountryRegular.test(String(this.loginForm.get('phone').value)) ||
      this.doubleZeroRegular.test(String(this.loginForm.get('phone').value))
    ) {
      this.loginForm.get('phone').setValue(380);
    }
  }

  public activateForm(): void {
    this.loginForm = DataForm.getLoginFormGroup();
  }

  public setValuePhone(element: any): void {
    const val = element.value;
    element.value = '';
    element.value = val;
  }

  public editPhone(event: boolean): void {
    if (event) {
      this.isResend = false;
      this.isAuthConfirm = false;
      this.isAuthCallConfirm = false;
    }
  }

  public verificationClient(): void {
    this.verificationService.sendSmsCode(this.phone, VerificationActionType.LOGIN, false, this.verificationType).subscribe(
      () => {
        this.isDisabled = false;
        this.isResend = false;
        this.isAuthConfirm = true;
        this.emitDataToParent();
      },
      () => (this.isDisabled = false),
    );
    this.window['dataLayer'].push({
      event: 'auth_funnel_phone_entered',
      text: 'Продовжити',
      funnel_step: 'Step 1',
      phone: this.phone,
    });
  }

  public verificationClientPhoneCall(): void {
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
    this.emitDataToParent();
  }

  public verificationClientPhoneCallWithCaptcha(): void {
    this.withCaptcha = true;
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
    this.emitDataToParent();
  }

  public verificationClientWithCaptcha(): void {
    from(this.recaptchaHelper.getRecaptchaToken('login'))
      .pipe(
        switchMap((reCaptchaToken: string) =>
          this.verificationService.sendSmsCodeWithCaptcha(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            false,
            this.verificationType,
          ),
        ),
        takeUntil(this.destroyStream$),
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isAuthConfirm = true;
          this.isResend = false;
          this.emitDataToParent();
        },
        () => (this.isDisabled = false),
      );
  }

  public timeGone(): void {
    if (this.isAuthCallConfirm) {
      this.isResend = true;
      this.isAuthCallConfirm = false;
      this.isAuthConfirm = false;
    }
    if (this.isAuthConfirm) {
      this.isResend = false;
      this.isAuthConfirm = true;
      this.isAuthCallConfirm = false;
    }
    this.emitDataToParent();
  }

  public chooseWayRepeat(type: VerificationType): void {
    this.verificationType = type;
    this.isResend = false;
    this.isAuthCallConfirm = false;
    this.isAuthConfirm = true;

    this.environment.recaptchaKEY_CODE ? this.verificationClientWithCaptcha() : this.verificationClient();
  }

  public emitDataToParent(): void {
    this.emitData.emit({
      isAuthCallConfirm: this.isAuthCallConfirm,
      isAuthConfirm: this.isAuthConfirm,
      isResend: this.isResend,
      withCaptcha: this.withCaptcha,
      verificationType: this.verificationType,
      smsViber: this.smsViber,
    });
    this.emitPhone.emit(this.phone);
  }

  public onSubmitRikki(): void {
    this.submitted = true;
    this.eventHelper.send(this.loginForm.value.phone, 'clicked button of ringing from 1');
    if (this.loginForm.invalid) {
      window['dataLayer'].push({
        event: 'auth_error',
        id: 'phone',
        text: 'Невірний формат телефону',
      });
    } else {
      this.isDisabled = true;
      this.phone = this.loginForm.value.phone;
      sessionStorage.setItem('phone', this.phone);
      this.environment.recaptchaKEY_CODE ? this.verificationClientPhoneCallWithCaptcha() : this.verificationClientPhoneCall();
    }
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isDisabled = true;
    this.phone = this.loginForm.value.phone;
    sessionStorage.setItem('phone', this.phone);
    this.environment.recaptchaKEY_CODE ? this.verificationClientWithCaptcha() : this.verificationClient();
  }
}
