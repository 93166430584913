export enum ClientStageEnum {
  UBKI_ID = 'UBKI_ID',
  REGISTRATION = 'REGISTRATION',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PASSPORT_DATA = 'PASSPORT_DATA',
  PHOTO = 'PHOTO',
  ADDRESS_DATA = 'ADDRESS_DATA',
  PROFILE = 'PROFILE',
  CARD = 'CARD',
  CLIENT = 'CLIENT',
  LOAN = 'LOAN',
  FINISHED_LOAN = 'FINISHED_LOAN',
  APPLICATION = 'APPLICATION',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
  APPLICATION_APPROVED = 'APPLICATION_APPROVED',
  APPLICATION_FINISHED = 'APPLICATION_FINISHED',
}
