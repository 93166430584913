<section [ngClass]="'sms-code-widget_' + type" class="sms-code-widget">
  <div class="sms-code__label text-center mb-2">
    <label *ngIf="verificationType === 'SMS'" for="sms-code">{{
      smsViber ? ('CONFIRM.SMS_CODE_LABEL_VIBER' | translate) : ('CONFIRM.SMS_CODE_LABEL' | translate)
    }}</label>
    <label *ngIf="verificationType === 'PHONE'" for="sms-code">{{ 'CONFIRM.PHONE_CODE_LABEL' | translate }}</label>
  </div>
  <div [ngClass]="{ 'code-sms': verificationType === 'SMS' }" class="sms-code__field">
    <input
      (blur)="markAsTouched()"
      (change)="change($event)"
      (focus)="registerFocusEvent($event)"
      (input)="input($event)"
      [class.in-valid]="control?.invalid && control?.touched"
      [class.valid]="control?.valid"
      [disabled]="isDisabled"
      [ngClass]="inputClasses"
      [placeholder]="placeholder"
      [value]="value"
      autocomplete="off"
      class="sms-code__input"
      id="sms-code"
      type="number"
    />

    <div *ngIf="control?.errors && control?.invalid && control?.touched" class="error-message">
      <span *ngIf="control?.errors.required">{{ 'validation.required' | translate }}</span>
      <span
        *ngIf="control?.errors.minlength"
        [translateParams]="{
          requiredLength: control?.errors.minlength.requiredLength
        }"
        translate
        >validation.minLength</span
      >
    </div>
  </div>
</section>
