import { AfterViewInit, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { InputName } from '../../../models/tracking.model';
import { FormExecutorService } from '../../../services/form-executor.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PromoCodeComponent,
      multi: true,
    },
  ],
})
export class PromoCodeComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public type: 'base' | 'avans' | 'starfin' | 'suncredit' = 'base';
  @Input()
  public colorScheme: 'base' | 'green' = 'base';

  @Input()
  public label: string = '';

  @Input()
  public placeholder: string = 'Placeholder';

  @Input()
  public value: string | number | any = '';

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public message: { info: string; status: string };

  @Input()
  public classes: string = '';

  @Input()
  public labelClasses: string = '';

  @Input()
  public maxCodeLength: number = 6;

  @Input()
  public isSpinner: boolean = false;

  @Output()
  public readonly clickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public readonly changeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public readonly focusEvent: EventEmitter<any> = new EventEmitter<any>();
  public hasFocus: boolean = false;
  public control: AbstractControl | null;

  constructor(private injector: Injector, private formService: FormExecutorService) {}

  public get inputClasses(): string {
    return `${this.classes}`;
  }

  public get invalid(): boolean {
    return this.message?.status === 'expired' || this.message?.status === 'absent';
  }

  public ngAfterViewInit(): void {
    this.getControl();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnInput(fn: any): void {
    this.onInput = fn;
    this.changeEvent.emit(fn.target.value);
  }

  public registerFocusEvent(event: any): void {
    this.hasFocus = true;
    this.focusEvent.emit(event);
  }

  public registerClickEvent(event: any): void {
    this.clickEvent.emit(event);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public input($event: Event): void {
    this.value = ($event.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  public change($event: Event): any {
    this.value = ($event.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  public markAsTouched(event: any): void {
    this.hasFocus = false;
    this.onInputBlur('promo', event.target.value);
    if (this.control?.invalid) {
      this.control?.markAsTouched();
    }
  }

  public onInputChange(fieldName: InputName, event: KeyboardEvent): void {
    this.formService.inputChangeEvent(fieldName, event);
  }

  public onInputPaste(fieldName: InputName, event: ClipboardEvent): void {
    this.formService.inputPasteEvent(fieldName, event);
  }

  public onKeyUp(fieldName: InputName, event: KeyboardEvent): void {
    this.formService.keyUpEvent(fieldName, event);
  }

  public onInputBlur(fieldName: InputName, event: FocusEvent): void {
    this.formService.inputBlurEvent(fieldName, event);
  }

  private getControl(): void {
    setTimeout(() => {
      this.control = this.injector.get(NgControl)?.control;
    });
  }

  private onChange: any = () => {};

  private onInput: any = () => {};

  private onTouched: any = () => {};
}
