import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import * as Models from '../models/models';

@Injectable()
export class CardService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  get(id: number): Observable<Models.ICard> {
    return this.http.get<Models.ICard>(`${this.urlService.cardUrl}/${id}`);
  }

  getCardList(): Observable<Models.ICard[]> {
    return this.http.get<Models.ICard[]>(this.urlService.cardUrl);
  }

  getRegisterData(successUrl: string, errorUrl?: string): Observable<any> {
    return this.http.post(this.urlService.cardUrl, {
      url: successUrl,
      errorUrl: errorUrl,
    });
  }
}
