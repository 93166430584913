import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { LoanService } from '../../services/loan.service';
import { Loan } from '../../modules/system/models/loan.model';
import { filter, finalize, tap } from 'rxjs/operators';
import { isNonNull } from '../../helpers/is-non-null.helper';
import { ClearStoreService } from '../services/clear-store.service';
import { Store } from './store';

@Injectable()
export class LastApplicationStorage extends Store {
  private loanSource: BehaviorSubject<Loan> = new BehaviorSubject<Loan>(null);
  private sub: Subscription;
  private isFirstLoading: boolean = false;

  constructor(private loanService: LoanService, public clearStoreService: ClearStoreService) {
    super(clearStoreService);
  }

  public get(): Observable<Loan> {
    if (!this.loanSource.value && !this.isFirstLoading) {
      this.loadFirstTime();
    }
    return this.loanSource.asObservable().pipe(
      filter(isNonNull), // убираем начальное значение в productSource (null)
    );
  }

  public set(loan: Loan): void {
    this.loanSource.next(loan);
  }

  public load(): Subscription {
    return this.loanService.getLast().subscribe((loan: Loan) => this.set(loan));
  }

  public updateLastApplicationByTimer(): void {
    if (this.sub) {
      return;
    }
    this.sub = interval(10000)
      .pipe(tap(() => this.load()))
      .subscribe();
  }

  public stopUpdateByTimer(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  public clear(): void {
    this.loanSource.next(null);
    this.stopUpdateByTimer();
  }

  public getStorageSubject(): BehaviorSubject<any> {
    return this.loanSource;
  }

  private loadFirstTime(): void {
    this.isFirstLoading = true;
    this.loanService
      .getLast()
      .pipe(
        tap((loan: Loan) => this.set(loan)),
        finalize(() => (this.isFirstLoading = false)),
      )
      .subscribe();
  }
}
