export class RegularExpression {
  static ONLY_NUMBERS = '^[0-9]+(\\.?[0-9]+)?$';
  static fieldPattern = "^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ \\–\\—\\-\\´\\`\\']{0,30}$";
  static addressPattern = "^[0-9А-ЯЁ а-щА-ЩЬьЮюЯяЇїІіЄєҐґ \\–\\—\\-\\´\\`\\']{0,30}$";
  static mailPattern = /^[A-Za-z0-9._%+-]+@+[A-Za-z0-9.]+(?!([Rr][Uu]))[A-Za-z]{2,}$/;

  static FIRST_NAME = "^(?![\\s'´`ʼ-]*$)[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\\s'´`ʼ‘-]+$";
  static LAST_NAME = "^(?![\\s'´`ʼ-]*$)[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\\s'´`ʼ‘-]+$";
  static MIDDLE_NAME = "^(?![\\s'´`ʼ‘]*$)[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\\s'´`ʼ‘]+$";
  static INN = '\\d{10}$';
  static MOBILE_PHONE =
    '^380(67|96|97|98|68|50|66|95|99|63|93|73|94|92|91|31|32|33|34|35|36|37|38|41|42|43|44|45|46|47|48|49|51|52|53|54|55|56|57|58|59|61|62|64|65|69)\\d{7}$';
  static ERROR_MOBILE_INPUT_DOUBLE_COUNTRY = /^380380$/;
  static ERROR_MOBILE_INPUT_DOUBLE_ZERO = /^3800$/;
  // Passport

  static NEW_PASSPORT_NUMBER = '\\d{9}$';
  static RECORD_NUMBER = '\\d{8}-\\d{5}$';
  static PASSPORT_SERIES = '^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ]{2}$';
  static PASSPORT_NUMBER = '\\d{6}$';
  static PASSPORT_ISSUED_BY = "^(?![.\\s'´`ʼ‘-]*$)[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\\.\\s'´`ʼ‘-]+$";
  static NEW_PASSPORT_ISSUED_BY = '\\d{4}$';
  static FULL_PASSPORT = '^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ]{2}\\d{6}$';

  // Address

  static POSTAL_CODE = '\\d{5}$';
  static REGION_TYPE = '^.*';
  static DISTRICT = '^.*';
  static LOCALITY = '^.*';
  static STREET = '^.*';
  static HOUSE = '^.*';
  static BLOCK = '^.*';
  static FLAT = '^.*';

  static CUSTOM_PASSPORT_PATTERN = {
    L: { pattern: new RegExp('^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ]') },
    N: { pattern: new RegExp('[0-9]') },
  };
}
