<section class="page">
  <app-header [navItems]="navItems" [phone]="phone" [steps]="steps"></app-header>
  <div class="page-container">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer [isSmallFooter]="true" [mod]="true"></app-footer>
</section>
<app-recaptcha></app-recaptcha>
