import * as Models from '../models/models';
import { ruLocale, ukLocale } from 'ngx-bootstrap/chronos';

export class DatepickerConfig {
  static readonly DatepickerLang: Models.IDatepicker = {
    ua: {
      lang: 'ua',
      locale: ukLocale,
    },
    ru: {
      lang: 'ru',
      locale: ruLocale,
    },
  };
}
