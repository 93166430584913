import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Config from '../../../../configs/general-config';
import { filter, takeUntil } from 'rxjs/internal/operators';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { VerificationActionType } from '../../../../enums/verification-action-type.enum';
import { VerificationType } from '../../../../enums/verification-type.enum';
import { ClientStore } from '../../../../shared/storage/client.store';
import { ClientModel } from '../../../../models/client.model';
import { MaskApplierService } from 'ngx-mask';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupType } from '../../../../types/group.type';
import { CountdownContainerComponent } from '../../../../shared/components/countdown-container/countdown-container.component';
import { WINDOW } from '../../../../shared/injections/window';
import { environment } from '../../../../environment';

@Component({
  selector: 'app-auth-auto-confirm',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: [`./templates/${environment.group}/style.sass`],
  providers: [UnsubscribeService],
})
export class AuthAutoConfirmComponent implements OnInit {
  @Input()
  public group: GroupType;

  @Input()
  public phone: string;

  @Input()
  public withCaptcha: boolean;

  @Input()
  public showPhone: boolean = true;

  @Input()
  public initialState: {
    count: number;
    breakpoint?: number;
    diameter?: number;
    strokeWidth?: number;
  };

  @Input()
  public redirectDone: boolean = false;

  @Output()
  public readonly verifyWayEvent: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  @Output()
  public readonly editPhoneEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly showPhoneEditorEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly timeGoneEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly redirectClientEvent: EventEmitter<ClientModel> = new EventEmitter<ClientModel>();

  public form: FormGroup;
  public path = Config.StaticDataConfig.redirectPathByClientStage;
  public action = VerificationActionType.LOGIN;
  public client: ClientModel;
  public verificationType: VerificationType = VerificationType.PHONE;
  public breakpointTimeOut: boolean = false;
  public timeOut: boolean = false;

  @ViewChild(CountdownContainerComponent)
  private counterContainer: CountdownContainerComponent;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private formBuilder: FormBuilder,
    @Self() private destroyStream$: UnsubscribeService,
    public router: Router,
    public clientStore: ClientStore,
    public route: ActivatedRoute,
    public maskService: MaskApplierService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public get phoneNumber(): string {
    const masks: { [key: string]: string } = {
      AVANS: '+00 000 000 00 00',
      STARFIN: '+00(000) 000 00 00',
      SUNCREDIT: '+00(000) 000 00 00',
      BASE: '00000 000 0000',
    };
    return this.maskService.applyMask(this.phone.toString(), masks[this.group]);
  }

  @HostListener('window:online', ['$event'])
  onOnlineEvent(): void {
    this.window.location.reload();
  }

  public ngOnInit(): void {
    this.initForm();
    this.phone = this.phone ? this.phone : sessionStorage.getItem('phone');
  }

  public editPhone(): void {
    this.editPhoneEvent.emit(); // use only for ZE and Banana
  }

  public breakpointOutHandler($event: boolean): void {
    this.breakpointTimeOut = $event;
  }

  public timeOutEventHandler($event: boolean): void {
    this.timeOut = $event;
  }

  public wayVerificationHandler(type: VerificationType): void {
    // do not change order
    this.verificationType = type;
    this.verifyWayEvent.emit(type);
    this.counterContainer.restartTimer();
    this.timeOut = false;
    this.breakpointTimeOut = false;
    this.changeDetector.detectChanges();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      agreement: this.formBuilder.group({
        agree: [true, Validators.requiredTrue],
        marketingConsent: [true],
      }),
    });

    this.form.controls.agreement
      .get('agree')
      .valueChanges.pipe(filter(Boolean), takeUntil(this.destroyStream$))
      .subscribe((value: boolean) => {
        if (this.redirectDone && this.client) {
          this.redirectClientEvent.emit(this.client);
        }
      });
  }
}
