import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Models from '../models/models';
import { PlatonPrivat } from '../models/models';
import { UrlService } from './url.service';
import { map, switchMap } from 'rxjs/operators';
import { ClientModel } from '../models/client.model';
import { EnvironmentType } from '../models/environment.type';

@Injectable()
export class BankIdService {
  public redirectToChoosenBank: Subject<string> = new Subject<string>();
  public bankIdHost: string;
  public group = this.environment.group;
  private forbiddenBanks = [];

  constructor(@Inject('environment') private environment: EnvironmentType, private http: HttpClient, private urlService: UrlService) {
    this.bankIdHost = this.environment.production ? 'https://id.bank.gov.ua/' : 'https://testid.bank.gov.ua/';
    // if (this.environment.name === NamesEnum.AVANS)
    //   this.forbiddenBanks = ['oshadbank'];
    this.redirectToChoosenBank
      .pipe(switchMap((bank: string) => this.bankIdVerification(bank)))
      .subscribe((redirectLink: string) => window.open(redirectLink, '_self'));
  }

  public getBankList(): Observable<Models.IBank[]> {
    return this.http
      .get<Models.IBank[]>(`${this.bankIdHost}api/banks`)
      .pipe(map((banks) => banks.filter((bank) => !this.forbiddenBanks.includes(bank.id))));
  }

  public bankIdVerification(bankId: string): Observable<string> {
    return this.http.post<string>(this.urlService.bankInfoUrl, { bankId });
  }

  public bankIdPlatonGet(): Observable<PlatonPrivat[]> {
    return this.http.get<Models.PlatonPrivat[]>(this.urlService.bankInfoPlatonUrl);
  }

  public getBankIdTestData(): Observable<ClientModel> {
    return this.http.post<ClientModel>(this.urlService.bankIdTestCallback, {});
  }
}
