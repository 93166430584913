import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { LoanActionType } from '../enums/loan-action-type.enum';
import { VerificationActionType } from '../enums/verification-action-type.enum';
import { ICallbackData, IContract, IPromoCode } from '../models/models';
import { ClientModel } from '../models/client.model';
import { Loan } from '../modules/system/models/loan.model';
import { LoanHistoryInterface } from '../interfaces/loan-history.interface';
import { IScheduleEvent } from '../interfaces/schedule-event';
import { PromoDetails } from '@libs/models/promoDetails.model';

@Injectable()
export class LoanService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  post(application: Loan): Observable<Loan> {
    return this.http.post<Loan>(this.urlService.applicationUrl, application);
  }

  testPost(application: Loan): Observable<Loan> {
    return this.http.post<Loan>(this.urlService.testApplicationUrl, application);
  }

  postBankIdCallback(callbackData: ICallbackData): Observable<ClientModel> {
    return this.http.post<ClientModel>(this.urlService.bankIdCallback, callbackData);
  }

  get(id: number): Observable<Loan> {
    return this.http.get<Loan>(`${this.urlService.loanUrl}/${id}`);
  }

  getLast(): Observable<Loan> {
    return this.http.get<Loan>(`${this.urlService.applicationUrl}${'?last'}`);
  }

  getCurrentLoan(): Observable<Loan[]> {
    return this.http.get<Loan[]>(this.urlService.loanUrl);
  }

  updateAll(): Observable<any> {
    return this.http.post(this.urlService.loanUrl, {});
  }

  paymentLoan(id: number, amount: number, url: string, reason: string): Observable<any> {
    const body = {
      amount,
      url,
      reason,
    };
    return this.http.post(`${this.urlService.loanContactUrl}/${id}/payments`, body);
  }

  freezingLoanPayment(id: number, operationId: number, url: string, reason: string, amount: number): Observable<any> {
    const body = {
      url,
      operationId,
      id,
      reason,
      amount,
    };
    return this.http.post(`${this.urlService.loanContactUrl}/${id}/payments`, body);
  }

  getLoanFreezingAgreement(id: number, operationBindingId: number) {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?freezing&operationBindingId=${operationBindingId}`);
  }

  newFreezingLoanPayment(id: number, url: string, reason: string, operationBindingId: number, amount: number): Observable<any> {
    const body = {
      url,
      id,
      reason,
      operationBindingId,
      amount,
    };
    return this.http.post(`${this.urlService.loanContactUrl}/${id}/payments`, body);
  }

  closeLoanPayment(id: number, operationId: number, url: string, reason: string, amount: number): Observable<any> {
    const body = {
      url,
      operationId,
      reason,
      amount,
    };
    return this.http.post(`${this.urlService.loanContactUrl}/${id}/payments`, body);
  }

  getWithDocuments(): Observable<any> {
    return this.http.get<Loan>(`${this.urlService.loanUrl}?documents`);
  }

  getHistory(): Observable<LoanHistoryInterface[]> {
    return this.http.get<LoanHistoryInterface[]>(`${this.urlService.loanUrl}`);
  }

  getLoanPassport(id: number): Observable<any> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?loan-passport`);
  }

  getFinProviderInfo(id: number): Observable<any> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?fin-provider-info`);
  }

  getLoanInformation(id: number): Observable<any> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?third-parties-data-notice`);
  }

  getApplicationRequest(id: number): Observable<any> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?application-request`);
  }

  getLoanContract(id: number): Observable<IContract> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?contract`);
  }

  putLoanAmount(id: number, amount: string): Observable<IContract> {
    if (!amount) return of(null);
    return this.http.put(`${this.urlService.loanContactUrl}/${id}?amount=${amount}`, {});
  }

  signContract(
    token: string,
    loanId: number,
    type: VerificationActionType,
    operationBindingId?: number,
    operationId?: number,
  ): Observable<Loan> {
    const body = {
      verificationToken: token,
      type: type,
      operationBindingId,
      operationId,
    };
    return this.http.post<Loan>(`${this.urlService.loanContactUrl}/${loanId}/actions`, body);
  }

  cancelLoan(id: number): Observable<Loan> {
    const body = {
      type: LoanActionType.CANCEL,
    };
    return this.http.post<Loan>(`${this.urlService.loanContactUrl}/${id}/actions`, body);
  }

  rejectLoan(id: number): Observable<Loan> {
    const body = {
      type: LoanActionType.REJECT,
    };
    return this.http.post<Loan>(`${this.urlService.loanContactUrl}/${id}/actions`, body);
  }

  verifyLoan(id: number): Observable<Loan> {
    const body = {
      type: LoanActionType.BACK_TO_VERIFY,
    };
    return this.http.post<Loan>(`${this.urlService.loanContactUrl}/${id}/actions`, body);
  }

  getLoanRestructuringAgreement(id: number, operationBindingId: number): Observable<IContract> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?restructuring&operationBindingId=${operationBindingId}`);
  }

  getNewLoanFreezingAgreement(id: number, operationBindingId: number): Observable<any> {
    return this.http.get(`${this.urlService.loanContactUrl}/${id}/documents?freezing&operationBindingId=${operationBindingId}`);
  }

  getDiscountAgreement(id: number): Observable<IContract> {
    return this.http.post(`${this.urlService.loanContactUrl}/${id}/discounts`, {});
  }

  usePromoCode(code: string): Observable<IPromoCode> {
    return this.http.get<IPromoCode>(`${this.urlService.promoCampaign}/?promoCode=${code}`);
  }

  getSchedule(id: number): Observable<IScheduleEvent[]> {
    return this.http.get<IScheduleEvent[]>(`${this.urlService.scheduleUrl}/${id}/expected-payments`);
  }

  getPromoDetails(loanId: number, code: string): Observable<PromoDetails> {
    return this.http.get<PromoDetails>(this.urlService.promoUrl.replace('{loan_id}', `${loanId}`).replace('{code}', code));
  }

  applyPromo(loanId: number, code: string): Observable<any> {
    return this.http.post(this.urlService.promoUrl.replace('{loan_id}', `${loanId}`).replace('{code}', code), {});
  }
}
