import * as Models from '../models/models';

import { EducationEnum } from '../enums/education.enam';
import { BusynessTypeEnum } from '../enums/busyness-type.enum';
import { CodewordTypeEnum } from '../enums/codeword-type.enum';
import { IncomeRangeEnum } from '../enums/income.enum';
import { LoanPurposeEnum } from '../enums/loan-purpose.enum';
import { ISelect } from '../interfaces/select.interface';

export class SelectConfig {
  static educationArray: Array<Models.ISelectEnum> = Object.keys(EducationEnum).map((key) => ({ name: key, value: EducationEnum[key] }));

  static employmentArray: Array<Models.ISelectEnum> = Object.keys(BusynessTypeEnum).map((key) => ({
    name: key,
    value: BusynessTypeEnum[key],
  }));

  static codewordTypeArray: Array<Models.ISelectEnum> = Object.keys(CodewordTypeEnum).map((key) => ({
    name: key,
    value: CodewordTypeEnum[key],
  }));

  static incomeRangeArray: Array<Models.ISelectEnum> = Object.keys(IncomeRangeEnum).map((key) => ({
    name: key,
    value: IncomeRangeEnum[key],
  }));

  static loanPurposeArray: Array<Models.ISelectEnum> = Object.keys(LoanPurposeEnum).map((key) => ({
    name: key,
    value: LoanPurposeEnum[key],
  }));

  static pepArray: Array<ISelect> = [
    { name: 'Так', value: true },
    { name: 'Ні', value: false },
  ];
}
