import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as Config from '../../configs/general-config';
import { takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeService } from '../services/unsubscribe.service';

@Injectable()
export class LanguageStore {
  public currentLang: BehaviorSubject<string> = new BehaviorSubject<string>(Config.LanguageConfig.ua);
  private storage: Storage = sessionStorage;

  constructor(private translate: TranslateService, private destroyStream$: UnsubscribeService) {
    this.translate.onLangChange
      .pipe(
        tap((event: LangChangeEvent) => this.setLang(event.lang)),
        takeUntil(this.destroyStream$),
      )
      .subscribe();
    this.getStorageLang();
  }

  public getStorageLang(): void {
    this.getLang()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(() => {
        const lang = this.storage.getItem('lang');
        lang !== null ? this.translate.use(lang) : '';
        return lang;
      });
  }

  public getLang(): Observable<any> {
    return this.currentLang.asObservable();
  }

  public setLang(lang: string): void {
    this.storage.setItem('lang', lang);
    this.currentLang.next(lang);
  }
}
