import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LoanPreviewComponent } from './loan-preview.component';

@NgModule({
  declarations: [LoanPreviewComponent],
  imports: [TranslateModule, CommonModule, MatButtonModule],
  exports: [LoanPreviewComponent],
})
export class LoanPreviewModule {}
