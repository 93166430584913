import { ChangeDetectorRef, Component, Inject, OnInit, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserVerificationService } from '../../../../services/user-verification.service';
import { AffiliateService } from '../../../../shared/services/affiliate.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { VerificationType } from '../../../../enums/verification-type.enum';
import { DataForm } from '../../../../shared/static-data/Data-form.static';
import { VerificationActionType } from '../../../../enums/verification-action-type.enum';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthPhoneComponent } from '../auth-phone/auth-phone.component';
import { ClientModel } from '../../../../models/client.model';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import * as Config from '../../../../configs/general-config';
import { from, Observable, of } from 'rxjs';
import { CalculateStore } from '../../../../shared/storage/calculate.store';
import { GroupType } from '../../../../types/group.type';
import { MatDialog } from '@angular/material/dialog';
import { AuthPhoneEditModalComponent } from '../auth-phone-edit-modal/auth-phone-edit-modal.component';
import { NamesEnum } from '../../../../enums/names.enum';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentType } from '../../../../models/environment.type';
import { WINDOW } from '../../../../shared/injections/window';
import { environment } from '../../../../environment';
import { tap } from 'rxjs/internal/operators';
import { ClientStore } from '../../../../shared/storage/client.store';
import { SendEventHelper } from '../../../../helpers/send-event.helper';
import { RecaptchaHelper } from '../../../../helpers/recaptcha.helper';
import { conditionalOperator } from '../../../../shared/operators/conditional.operator';
import { TokenResponse } from '@libs/models/token-response.model';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [UnsubscribeService],
})
export class AuthLoginComponent implements OnInit {
  public group: GroupType;
  public production: boolean = false;
  public verificationType: VerificationType = VerificationType.PHONE;
  public loginForm: FormGroup;
  public showPhone: boolean = false;
  public submitted: boolean = false;
  public isAuthConfirm: boolean = false;
  public isAuthCallConfirm: boolean = false;
  public isResend: boolean = false;
  public withCaptcha: boolean = false;
  public phone: string;
  public isDisabled: boolean = false;
  public rikki: boolean = false;
  public show: boolean = false;
  public smsViber: boolean = false;
  public isPreview$: Observable<boolean>;
  public redirectDone: boolean = false;
  @ViewChild(AuthPhoneComponent, { static: false })
  phoneCom: AuthPhoneComponent;
  public initialState: {
    count: number;
    breakpoint?: number;
    diameter?: number;
    strokeWidth?: number;
  };
  private ivrCallingData: string = '';
  private path = Config.StaticDataConfig.redirectPathByClientStage;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject('environment') private environment: EnvironmentType,
    public formBuilder: FormBuilder,
    public verificationService: UserVerificationService,
    public affiliateService: AffiliateService,
    public activatedRoute: ActivatedRoute,
    @Self() private destroyStream$: UnsubscribeService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthenticationService,
    private router: Router,
    public route: ActivatedRoute,
    private calculateStore: CalculateStore,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private clientStore: ClientStore,
    private eventHelper: SendEventHelper,
    private recaptchaHelper: RecaptchaHelper,
  ) {
    this.group = this.environment.group;
    this.production = this.environment.production;
    this.isPreview$ = this.calculateStore.isPreviewVisible;
  }

  public get type(): string {
    const types = {
      AVANS: 'avans',
      STARFIN: 'starfin',
      SUNCREDIT: 'suncredit',
      BASE: 'base',
    };
    return types[this.group] ? types[this.group] : 'base';
  }

  public ngOnInit(): void {
    this.activateForm();
    this.setQueryParams();
    this.route.queryParams.subscribe((params: Params) => {
      this.show = params.show;
      this.rikki = params.rikki;
    });
    this.checkIvrEvent();

    if (this.environment.group === NamesEnum.AVANS) {
      this.window['dataLayer'].push({ Step1Login: 'Step1Login' });
    }
  }

  public verificationClientIvr(): void {
    this.ivrCallingData = this.cookieService.get('ivr_calling_data');
    const count: number = +this.cookieService.get('count-timer');
    this.initialState = {
      count: count ? count : 60,
      breakpoint: this.group !== NamesEnum.AVANS ? (count ? count - 1 : 59) : 10, // ( count - 1 ) last value emit
      diameter: 140,
      strokeWidth: 10,
    };
    switch (environment.group) {
      case NamesEnum.STARFIN: {
        this.initialState.count = 30;
        this.initialState.breakpoint = 29;
        break;
      }

      case NamesEnum.SUNCREDIT: {
        this.initialState.count = 10;
        this.initialState.breakpoint = 9;
        break;
      }
    }
    from(this.recaptchaHelper.getRecaptchaToken('login'))
      .pipe(
        conditionalOperator({
          condition: () => !!this.ivrCallingData,
          trueCase: () => ({}),
          falseCase: (reCaptchaToken: string) =>
            this.verificationService.getIvrCall(this.phone, VerificationActionType.LOGIN, reCaptchaToken, VerificationType.IVR).pipe(
              tap((token: any) => {
                const expirationDate = new Date();
                expirationDate.setMinutes(expirationDate.getMinutes() + 1);
                this.ivrCallingData = token.token;
                this.cookieService.set('ivr_calling_data', token.token, expirationDate);
              }),
            ),
        }),
        switchMap(() => {
          return this.verificationService.getServerSentEventIvr({
            token: this.ivrCallingData,
          });
        }),
        switchMap((token) => this.authService.loginUserByVerificationToken(token, this.phone)),
        tap((tokenResp: TokenResponse) => {
          if (tokenResp.redirectUrl) this.window.location.href = tokenResp.redirectUrl;
        }),
        tap((tokenResp) => this.authService.setToken(tokenResp)),
        switchMap(() =>
          this.verificationService.ivrUnsubscribe(this.ivrCallingData).pipe(
            catchError(() => {
              this.eventHelper.send(this.phone, `error in method ivr login`);
              return of();
            }),
          ),
        ),
        switchMap(() => this.clientStore.getClient()),
        takeUntil(this.destroyStream$),
      )
      .subscribe(
        (client: ClientModel) => {
          this.eventHelper.send(this.phone, 'success all method ivr login');
          this.redirectDone = true;
          this.cookieService.delete('ivr_calling_data');
          this.cookieService.delete('count-timer', '/');
          this.redirectClientHandler(client);
        },
        () => this.eventHelper.send(this.phone, `error in method ivr login`),
      );
  }

  public setQueryParams(): void {
    this.activatedRoute.queryParams.pipe(take(1), takeUntil(this.destroyStream$)).subscribe((params: Params) => {
      this.affiliateService.setQueryParamsFromUrl(params);
    });
  }

  public setValuePhone(element: any): void {
    const val = element.value;
    element.value = '';
    element.value = val;
  }

  public editPhoneHandler(): void {
    this.isResend = false;
    this.isAuthConfirm = false;
    this.isAuthCallConfirm = false;
    this.changeDetectorRef.detectChanges();
    this.phoneCom.loginForm.patchValue({
      phone: this.phone,
    });
  }

  public showPhoneEditorModal(): void {
    const modal = this.dialog.open(AuthPhoneEditModalComponent, {
      panelClass: 'phone-edit-dialog',
      data: {
        phoneNumber: this.phone,
        group: this.group,
      },
      width: '600px',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });

    modal
      .afterClosed()
      .pipe(
        conditionalOperator({
          condition: (phone: any) => typeof phone === 'number',
          trueCase: (phone: number) => {
            this.isResend = false;
            this.isAuthConfirm = false;
            this.isAuthCallConfirm = false;
            this.changeDetectorRef.detectChanges();
            this.phoneCom.loginForm.patchValue({
              phone: phone,
            });
            this.cancelPrevIvr();
          },
        }),
        take(1),
      )
      .subscribe();
  }

  public verificationClient(): void {
    this.verificationService
      .sendSmsCode(this.phone, VerificationActionType.LOGIN, false, this.verificationType)
      .pipe(take(1))
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isResend = false;
          this.isAuthConfirm = true;
        },
        () => (this.isDisabled = false),
      );
  }

  public verificationClientWithCaptcha(): void {
    from(this.recaptchaHelper.getRecaptchaToken('login'))
      .pipe(
        switchMap((reCaptchaToken: string) =>
          this.verificationService.sendSmsCodeWithCaptcha(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            false,
            this.verificationType,
          ),
        ),
        takeUntil(this.destroyStream$),
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isAuthConfirm = true;
          this.isResend = false;
        },
        () => (this.isDisabled = false),
      );
  }

  public timeGoneHandler(): void {
    if (this.isAuthCallConfirm) {
      this.isResend = true;
      this.isAuthCallConfirm = false;
      this.isAuthConfirm = false;
    }
    if (this.isAuthConfirm) {
      this.isResend = false;
      this.isAuthConfirm = true;
      this.isAuthCallConfirm = false;
    }
  }

  public chooseWayRepeat(type: VerificationType): void {
    this.verificationType = type;
    this.isResend = false;
    this.isAuthCallConfirm = false;
    this.isAuthConfirm = true;

    this.environment.recaptchaKEY_CODE ? this.verificationClientWithCaptcha() : this.verificationClient();
  }

  public setValues(event: any): void {
    this.isAuthCallConfirm = event.isAuthCallConfirm;
    this.isAuthConfirm = event.isAuthConfirm;
    this.isResend = event.isResend;
    this.withCaptcha = event.withCaptcha;
    this.verificationType = event.verificationType as VerificationType;
    this.smsViber = event.smsViber;

    if (this.isAuthCallConfirm) {
      this.verificationClientIvr();
    }
  }

  public setPhone(number: string): void {
    this.phone = number;
  }

  public redirectClientHandler(client: ClientModel): void {
    this.path.form[client.stage] ? this.router.navigate(['form', this.path.form[client.stage]]) : this.router.navigate(['main', 'loan']);
    this.authService.setLoggedIn(true);
  }

  private checkIvrEvent(): void {
    if (this.cookieService.get('ivr_calling_data') && sessionStorage.getItem('phone')) {
      this.isResend = false;
      this.isAuthConfirm = false;
      this.isAuthCallConfirm = true;
      this.phone = sessionStorage.getItem('phone');
      this.verificationClientIvr();
      this.changeDetectorRef.detectChanges();
    } else {
      this.cookieService.delete('ivr_calling_data');
      this.cookieService.delete('count-timer', '/');
    }
  }

  private cancelPrevIvr(): void {
    const ivrCallData = this.cookieService.get('ivr_calling_data');
    if (!ivrCallData) return this.phoneCom.onSubmitRikki();
    this.verificationService
      .ivrUnsubscribe(ivrCallData, true)
      .pipe(take(1))
      .subscribe(() => {
        this.cookieService.delete('ivr_calling_data');
        this.cookieService.delete('count-timer', '/');
        this.phoneCom.onSubmitRikki();
      });
  }

  private activateForm(): void {
    this.loginForm = DataForm.getLoginFormGroup();
  }
}
