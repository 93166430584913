import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as Config from './../../configs/general-config';
import * as moment from 'moment';
import { EnvironmentType } from '../../models/environment.type';
import { IAffiliate } from '../../models/models';
import { Params } from '@angular/router';
import { NamesEnum } from '../../enums/names.enum';

@Injectable()
export class AffiliateService {
  public affiliateParam: IAffiliate = {};
  public affiliate = Config.StaticDataConfig.affiliate;

  constructor(private cookieService: CookieService, @Inject('environment') private environment: EnvironmentType) {}

  public setAffiliate(): void {
    this.affiliate.forEach((aff: string) => {
      this.affiliateParam[aff] = this.cookieService.get(aff);
    });
  }

  public setQueryParamsFromUrl(params: Params): void {
    const date = moment(new Date()).format('YYYY-MM-DD');
    Object.keys(params).forEach((key: string) => {
      if (this.affiliate.includes(key)) {
        this.cookieService.set(key, params[key], 30, '/', this.environment.cookieDomain);
        this.cookieService.set('affiliateCreatedAt', date, 30, '/', this.environment.cookieDomain);
      }
    });
  }

  public affiliateCookieFactory(params): void {
    Object.keys(params).forEach((key) => {
      this.cookieService.set(key, params[key], 3, '/', this.environment.cookieDomain);
    });
  }

  public getAffiliate(): IAffiliate {
    return this.affiliateParam;
  }
}
