import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
  public fileToBlob(file: File): Observable<Blob | null> {
    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        observer.next(blob);
        observer.complete();
      };

      reader.onerror = (error) => {
        observer.next(null);
        observer.complete();
      };

      reader.readAsArrayBuffer(file);
    });
  }

  public base64ToBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
