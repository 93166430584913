import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass'],
})
export class DialogComponent {
  @Input()
  public showCloseBtn: boolean = true;

  @Input()
  public size: 'small' | 'medium' | 'big' | 'large' | 'xLarge' = 'small';

  @Output()
  public readonly closeEvent: EventEmitter<'close' | 'esc'> = new EventEmitter<'close' | 'esc'>();

  public get dialogClasses(): string {
    return `dialog dialog-${this.size} `;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.closeEvent.emit('esc');
  }

  public closeDialog(): void {
    this.closeEvent.emit('close');
  }
}
