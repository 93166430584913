import { Injectable } from '@angular/core';
import { ActionType, FormControlData, InputName, RequestChangeAction, RequsetData, ChangeAction } from '../models/tracking.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private sessionStartTime: number;
  private isThirdPartyClick: boolean = false;
  private formControls: FormControlData[] = [];
  private formControlsFillingOrder: InputName[] = [];

  constructor(private http: HttpClient, private urlService: UrlService) {}

  public cleanAllData(): void {
    this.startSession();
    this.formControls = [];
    this.formControlsFillingOrder = [];
  }

  public addAction(fieldName: InputName, actionType: ActionType, value: string): void {
    if (this.formControlsFillingOrder[this.formControlsFillingOrder.length - 1] != fieldName) {
      // If we didn't edit field (fieldname) as the last one
      this.formControlsFillingOrder.push(fieldName);
    }

    const control = this.formControls.find((control) => control.inputName == fieldName);

    if (!control) {
      // Here we should add an info about this field

      const control: FormControlData = {
        inputName: fieldName,
        isFocused: true,
        actions: [
          {
            actionType: actionType,
            prevValue: '',
            updatedValue: value,
            actionStartTime: Date.now(),
            actionEndTime: Date.now(),
          },
        ],
      };

      this.formControls.push(control);
      return;
    }

    // If we already have info about this field
    if (control.actions[control.actions.length - 1].actionType == actionType && actionType != 'range' && actionType != 'select') {
      // If the editing type is the same with previous
      if (!control.isFocused) {
        const prevDuration =
          control.actions[control.actions.length - 1].actionEndTime - control.actions[control.actions.length - 1].actionStartTime;

        control.actions[control.actions.length - 1].actionStartTime = Date.now() - prevDuration;

        control.isFocused = true;
      }

      control.actions[control.actions.length - 1].updatedValue = value;
      return;
    }

    const newAction: ChangeAction = {
      actionType: actionType,
      prevValue: control.actions.length ? control.actions[control.actions.length - 1].updatedValue : '',
      updatedValue: value,
      actionStartTime: Date.now(),
      actionEndTime: Date.now(),
    };
    control.actions.push(newAction);
  }

  public setLastActionEnd(fieldName: InputName, value: string): void {
    const field = this.formControls.find((control) => control.inputName == fieldName);
    if (field) {
      field.actions[field.actions.length - 1].actionEndTime = +Date.now();
      field.actions[field.actions.length - 1].updatedValue = value;
    }
  }

  public setBlured(fieldName: InputName, value: string): void {
    const field = this.formControls.find((control) => control.inputName == fieldName);
    if (field) {
      field.isFocused = false;
      field.actions[field.actions.length - 1].updatedValue = value;
    }
  }

  public sendRequest(): Observable<any> {
    if (!localStorage.getItem('isThirdPartyClick')) {
      this.isThirdPartyClick = true;
      localStorage.removeItem('isThirdPartyClick');
    }

    const reqBody: RequsetData = {
      formControlsFillingOrder: this.formControlsFillingOrder,
      initialTime: +Date.now() - this.sessionStartTime,
      isThirdPartyClicks: this.isThirdPartyClick,
      formControls: this.formControls.map((control) => {
        const actions: RequestChangeAction[] = control.actions.map((item) => ({
          actionType: item.actionType,
          prevValue: item.prevValue,
          updatedValue: item.updatedValue,
          actionDuration: item.actionEndTime - item.actionStartTime,
        }));
        return {
          inputName: control.inputName,
          actions: actions,
          actionsDuration: actions.reduce((acc, value) => acc + value.actionDuration, 0),
        };
      }),
    };
    return this.http.post(this.urlService.trackingUrl, reqBody);
  }

  public startSession = () => {
    this.sessionStartTime = Date.now();
  };

  public setThirdPartyClick = () => {
    this.isThirdPartyClick = true;
  };
}
