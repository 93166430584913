<section [ngClass]="['promo-code_' + type, 'promo-code-style_' + colorScheme]" class="promo-code">
  <div *ngIf="label" [class]="labelClasses" class="promo-code-label">
    {{ label }}
  </div>
  <div class="promo-code-field">
    <div class="promo-code-inner">
      <input
        (blur)="markAsTouched($event)"
        (change)="change($event)"
        (focus)="registerFocusEvent($event)"
        (input)="input($event)"
        (keydown)="onInputChange('promo', $event)"
        (keyup)="onKeyUp('promo', $event)"
        (paste)="onInputPaste('promo', $event)"
        [class.in-valid]="control?.invalid || invalid"
        [disabled]="isDisabled"
        [maxLength]="maxCodeLength"
        [ngClass]="inputClasses"
        [placeholder]="placeholder"
        [value]="value"
        class="promo-code-input"
      />
      <div *ngIf="isSpinner" class="promo-code-spinner">
        <mat-spinner [diameter]="24" matSuffix></mat-spinner>
      </div>
    </div>
    <div *ngIf="message?.info" [ngClass]="message.status" class="promo-code-message text-center">
      {{ message.info }}
    </div>
  </div>
</section>
