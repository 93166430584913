import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaComponent } from './recaptcha.component';

@NgModule({
  declarations: [RecaptchaComponent],
  imports: [CommonModule],
  exports: [RecaptchaComponent],
})
export class RecaptchaModule {}
