import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PromoCodeComponent } from './promo-code.component';

@NgModule({
  declarations: [PromoCodeComponent],
  imports: [TranslateModule, CommonModule, MatProgressSpinnerModule],
  exports: [PromoCodeComponent],
})
export class PromoCodeModule {}
