import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Observable } from 'rxjs';
import { IContact } from '../../../models/models';
import { ICalculatePreview } from '../../../interfaces/calculate-preview.interface';
import { CalculateStore } from '../../storage/calculate.store';
import { ClientStore } from '../../storage/client.store';
import { DeviceService } from '../../../services/device.service';
import { WINDOW } from '../../injections/window';
import { NavItem } from '../../../models/nav-item.interface';
import { IItem } from '../stepper-widget/stepper-widget.component';
import { EnvironmentType } from '../../../models/environment.type';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environment';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { NamesEnum } from '../../../enums/names.enum';
import { loginHelper } from '../../../helpers/login.helper';

@Component({
  selector: 'app-header',
  templateUrl: `./templates/${environment.name}/page.html`,
  styleUrls: [`./templates/${environment.name}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  public navItems: NavItem[] = [];

  @Input()
  public webSiteItems: NavItem[] = [];

  @Input()
  public phone: IContact;

  @Input()
  public isWebSite: boolean = false;

  @Input()
  public landingClasses: string = '';

  @Input()
  public steps: IItem[] = [];

  public env!: EnvironmentType;
  public hamburger: boolean = false;
  public isLogged$: Observable<boolean>;
  public tabletWide$: Observable<boolean>;
  public preview$: Observable<ICalculatePreview>;
  public isPreview$: Observable<boolean>;
  public isMenu$: Observable<boolean>;
  public isStepsVisible: boolean = false;
  private body = this.document.querySelector('body');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private readonly window: Window,
    @Inject('environment') public environment: EnvironmentType,
    private authService: AuthenticationService,
    private calculateStore: CalculateStore,
    private clientStore: ClientStore,
    private readonly deviceService: DeviceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.isLogged$ = this.authService.isLoggedIn();
    this.tabletWide$ = this.deviceService.isTabletWide;
    this.preview$ = this.calculateStore.getPreviewParam$;
    this.isPreview$ = this.calculateStore.isPreviewVisible;
    this.isMenu$ = this.clientStore.isMenuVisible$;
    this.env = this.environment;
  }

  public get isBorder(): boolean {
    return !['/form/profile', '/form/busyness', '/system-group/scanner'].includes(this.router.url);
  }

  public hamburgerEventHandler(v?: boolean): void {
    this.hamburger = v ?? !this.hamburger;
    this.hamburger ? this.body.classList.add('overflow-hidden') : this.body.classList.remove('overflow-hidden');

    this.hamburger
      ? (this.window.onscroll = () => {
          this.window.scrollTo(0, 0);
        })
      : (this.window.onscroll = () => {
          this.window.scrollTo();
        });
  }

  public login(): void {
    const queryParams = new URLSearchParams({
      action: 'login',
    });
    loginHelper(this.window, queryParams, this.environment.CLICK_URL);
  }

  public logoutHandler(): void {
    if (this.environment.group !== NamesEnum.AVANS) {
      this.logout();
    } else {
      const confirmLogout = this.dialog.open(LogoutDialogComponent, {
        panelClass: 'logout-dialog',
        disableClose: true,
        width: '320px',
      });
      confirmLogout
        .afterClosed()
        .pipe(filter(Boolean), take(1))
        .subscribe(() => {
          this.logout();
        });
    }
  }

  private logout(): void {
    this.hamburgerEventHandler(false);
    this.hamburger = false;
    this.authService.logout();
    this.calculateStore.showPreview = false;
  }

  public onStepsVisibleChange(v: boolean): void {
    this.isStepsVisible = v;
  }
}
