import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoanService } from '../../services/loan.service';
import { Loan } from '../../modules/system/models/loan.model';
import { finalize, filter, tap } from 'rxjs/operators';
import { isNonNull } from '../../helpers/is-non-null.helper';
import { Store } from './store';
import { ClearStoreService } from '../services/clear-store.service';

@Injectable()
export class LoansStorage extends Store {
  private loansWithDocuments: BehaviorSubject<Loan[]> = new BehaviorSubject<Loan[]>(null);
  private isFirstLoading: boolean = false;

  constructor(private loanService: LoanService, public clearStoreService: ClearStoreService) {
    super(clearStoreService);
  }

  public getWithDocuments(payment: boolean): Observable<Loan[]> {
    if (payment || (!this.loansWithDocuments.value && !this.isFirstLoading)) {
      this.loadFirstTimeWithDocuments();
    }
    return this.loansWithDocuments.asObservable().pipe(
      filter(isNonNull), // убираем начальное значение в productSource (null)
    );
  }

  public setWithDocuments(loans: Loan[]): void {
    this.loansWithDocuments.next(loans);
  }

  public loadWithDocuments(): Subscription {
    return this.loanService.getWithDocuments().subscribe((response) => this.setWithDocuments(response));
  }

  public getStorageSubject(): BehaviorSubject<any> {
    return this.loansWithDocuments;
  }

  private loadFirstTimeWithDocuments(): void {
    this.isFirstLoading = true;
    this.loanService
      .getWithDocuments()
      .pipe(
        tap((response) => this.setWithDocuments(response)),
        finalize(() => (this.isFirstLoading = false)),
      )
      .subscribe();
  }
}
