<app-dialog [showCloseBtn]="true" size="medium">
  <section class="loan-editor" dialogContent>
    <h2 class="loan-editor-header fw-bold text-center">{{ 'Зміна параметрів кредиту' | translate }}</h2>
    <div [formGroup]="form" class="loan-editor-content">
      <div class="loan-editor-calculate">
        <app-calculate-widget
          (calculateChangeEvent)="calculateChangeHandler($event)"
          [amount]="amount"
          [group]="data.group"
          [isDisabledPromo]="isDisabledPromo"
          [product]="data.product"
          [term]="term"
          formGroupName="calculate"
        ></app-calculate-widget>
      </div>
      <div class="loan-editor-info">
        <app-promo-code
          [isDisabled]="isDisabledPromo"
          [isSpinner]="showSpinner"
          [label]="'LOAN.BOOKING.PROMO_LABEL' | translate"
          [maxCodeLength]="MIN_CODE_LENGTH"
          [message]="message"
          [placeholder]="'LOAN.BOOKING.PROMO_PLACEHOLDER' | translate"
          [type]="'starfin'"
          formControlName="code"
        ></app-promo-code>
        <div class="w-100 mt-4 mb-3">
          <app-loan-schedule-widget
            [character]="'WORKTABLE'"
            [preview]="preview$ | async"
            [schedule]="schedule$ | async"
            [type]="'starfin'"
          ></app-loan-schedule-widget>
        </div>
        <div class="d-flex justify-content-center">
          <app-button (click)="setChanges()" [label]="'BUTTON.EMPLOY' | translate" classes="fs-15 fw-bold" color="secondary" size="large">
          </app-button>
        </div>
      </div>
    </div>
  </section>
</app-dialog>
