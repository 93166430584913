import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CountdownContainerComponent } from './countdown-container.component';
import { CounterWidgetModule } from '../counter-widget/counter-widget.module';

@NgModule({
  declarations: [CountdownContainerComponent],
  imports: [TranslateModule, CommonModule, CounterWidgetModule],
  exports: [CountdownContainerComponent],
})
export class CountdownContainerModule {}
