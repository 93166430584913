<button
  (click)="clickEvent.emit($event)"
  [class.spinner]="disabled"
  [disabled]="disabled"
  [ngClass]="btnClasses"
  [type]="type"
  mat-button
  type="button"
>
  <img *ngIf="img?.url" [src]="img.url" [style.height]="img.height || ''" [style.width]="img.width || ''" alt="icon" class="ml-1 mr-1" />
  <span *ngIf="label" class="d-inline-flex align-items-center justify-content-center">{{ label }}</span>
</button>
