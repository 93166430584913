import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Self } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ICalculatePreview } from '../../interfaces/calculate-preview.interface';
import { IScheduleEvent } from '../../interfaces/schedule-event';
import { ILoanProduct, IPromoCode } from '../../models/models';
import { CalculateStore } from '@libs/shared/storage/calculate.store';
import { ProductStore } from '@libs/shared/storage/product.store';
import { LoanService } from '@libs/services/loan.service';
import { ToasterService } from '@libs/shared/components/toaster-widget/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeService } from '@libs/shared/services/unsubscribe.service';
import { GroupType } from '../../types/group.type';
import { environment } from '../../environment';

@Component({
  selector: 'app-loan-editor-modal',
  templateUrl: `./templates/${environment.group}/page.html`,
  styleUrls: [`./templates/${environment.group}/style.sass`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService],
})
export class LoanEditorModalComponent implements OnInit {
  public form: FormGroup;
  public MIN_CODE_LENGTH: number = 6;
  public isDisabledPromo: boolean = false;
  public showSpinner: boolean = false;
  public message: { info: string; status: string };
  public term: number;
  public amount: number;
  public preview$: Observable<ICalculatePreview>;
  public schedule$: Observable<Array<IScheduleEvent>>;
  public isShowPromo: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { product: ILoanProduct; group: GroupType },
    public dialogRef: MatDialogRef<LoanEditorModalComponent>,
    private calculateStore: CalculateStore,
    private productStore: ProductStore,
    private formBuilder: FormBuilder,
    private loanService: LoanService,
    private toaster: ToasterService,
    private translate: TranslateService,
    @Self() private destroyStream$: UnsubscribeService,
    private cd: ChangeDetectorRef,
  ) {
    this.preview$ = this.calculateStore.getPreviewParam$;
    this.schedule$ = this.calculateStore.schedule$;
    this.calculateStore.initSchedule(this.data.product);
  }

  public ngOnInit(): void {
    this.initForm();
    this.setFormValues(this.data.product);
    if (sessionStorage.getItem('promoCode')) {
      this.form.get('code').setValue(sessionStorage.getItem('promoCode'));
    }
  }

  public showPromo(): void {
    this.isShowPromo = !this.isShowPromo;
  }

  public calculateChangeHandler($event: { amount: number; term: number; interest: number }): void {
    this.amount = $event.amount;
    this.term = $event.term;
    this.calculateStore.updatePreviewParam($event.amount, $event.term, $event.interest, true);
    this.calculateStore.updateSchedule(
      $event.amount,
      $event.term,
      $event.interest,
      this.data.product.is360,
      this.data.product.firstPeriodInterest,
      this.data.product.promoRate,
    );
  }

  public setChanges(): void {
    this.dialogRef.close();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      calculate: this.formBuilder.group({
        amount: [null],
        term: [null],
      }),
      code: [null, Validators.compose([Validators.min(this.MIN_CODE_LENGTH)])],
    });

    this.form
      .get('code')
      .valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        filter((code) => code.length === this.MIN_CODE_LENGTH),
        tap(() => {
          this.isDisabledPromo = true;
          this.showSpinner = true;
        }),
        switchMap((code) => this.loanService.usePromoCode(code)),
        tap(() => {
          this.isDisabledPromo = false;
          this.showSpinner = false;
        }),
        takeUntil(this.destroyStream$),
      )
      .subscribe((response: IPromoCode) => {
        if (!response) {
          this.message = {
            info: `${this.translate.instant('promo.status.absent')}`,
            status: 'absent',
          };
          this.form.get('code').setErrors({ untrue: true });
        } else if (response.actual) {
          if (response.actual) {
            this.isDisabledPromo = true;
            sessionStorage.setItem('promoCode', this.form.get('code').value);
            this.data.product = {
              ...this.data.product,
              promoRate: response.interest,
            };
            this.calculateStore.init(this.data.product);
            this.message = {
              info: `${this.translate.instant('promo.status.active', {
                interest: response.interest,
              })}`,
              status: 'active',
            };
            this.calculateStore.updatePreviewParam(
              this.form.controls.calculate.get('amount').value,
              this.form.controls.calculate.get('term').value,
              this.data.product.interest,
              true,
            );
            this.calculateStore.updateSchedule(
              this.form.controls.calculate.get('amount').value,
              this.form.controls.calculate.get('term').value,
              this.data.product.interest,
              this.data.product.is360,
              this.data.product.firstPeriodInterest,
              this.data.product.promoRate,
            );
          } else {
            this.message = {
              info: `${this.translate.instant('promo.status.expired')}`,
              status: 'expired',
            };
          }
        }
        this.cd.detectChanges();
      });
  }

  private setFormValues(product: ILoanProduct): void {
    this.term = this.calculateStore.term(product);
    this.amount = this.calculateStore.amount(product);
    this.form.controls.calculate.get('amount').setValue(this.amount);
    this.form.controls.calculate.get('term').setValue(this.term);
  }
}
