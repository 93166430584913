import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VerificationType } from '../../../enums/verification-type.enum';
import { GroupType } from '../../../types/group.type';

@Component({
  selector: 'app-buttons-way-code',
  templateUrl: './buttons-way-code.component.html',
  styleUrls: ['./buttons-way-code.component.sass'],
})
export class ButtonsWayCodeComponent {
  @Input()
  public group: GroupType;

  @Input()
  public showPhone: boolean = true;

  @Output()
  public readonly wayRepeat: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  public verificationType = VerificationType;
}
