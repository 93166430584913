import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ErrorHandlerService } from '../services/error-handler.service';
import { EnvironmentType } from '../models/environment.type';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject('environment') private environment: EnvironmentType, public errorHandlerService: ErrorHandlerService) {}

  public handleError(error: any): void {
    const stackLines = error.stack.split('\n');
    let errorLocation: string = '';
    if (stackLines.length > 1) {
      errorLocation = stackLines[1].trim();
    }
    const website = this.environment.name;
    const token = sessionStorage.getItem('jwt_token');
    const phone = sessionStorage.getItem('phone');
    const url = window.location.pathname;
    if (this.environment.production) {
      this.errorHandlerService.sendErrors('ERROR', error, website, url, phone, token, errorLocation).subscribe(() => {});
    }
  }
}
