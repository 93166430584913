import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@libs/services/device.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { LoanPreviewModule } from '../loan-preview/loan-preview.module';
import { LoanEditorModalModule } from '../../../modals/loan-editor-modal/loan-editor-modal.module';
import { HeaderComponent } from './header.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { HeaderMobileNavigationComponent } from './components/header-mobile-navigation/header-mobile-navigation.component';
import { HeaderDesktopNavigationComponent } from './components/header-desktop-navigation/header-desktop-navigation.component';
import { RouterModule } from '@angular/router';
import { StepperWidgetModule } from '../stepper-widget/stepper-widget.module';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule } from '../dialog/dialog.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNavigationComponent,
    HeaderMobileNavigationComponent,
    HeaderDesktopNavigationComponent,
    LogoutDialogComponent,
  ],
  exports: [
    HeaderComponent,
    HeaderNavigationComponent,
    HeaderMobileNavigationComponent,
    HeaderDesktopNavigationComponent,
    LogoutDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    DialogModule,
    ButtonModule,
    LoanPreviewModule,
    LoanEditorModalModule,
    StepperWidgetModule,
  ],
  providers: [DeviceService],
})
export class HeaderModule {}
