import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ClientService } from '../../services/client.service';
import { ClientModel } from '../../models/client.model';
import { Location } from '@angular/common';
import { IdentOptionsEnum } from '../../enums/ident-options.enum';
import { filter, finalize, tap } from 'rxjs/operators';
import { isNonNull } from '../../helpers/is-non-null.helper';
import { ClearStoreService } from '../services/clear-store.service';
import { Store } from './store';
import { CookieService } from 'ngx-cookie-service';
import { SendEventHelper } from '../../helpers/send-event.helper';

@Injectable()
export class ClientStore extends Store {
  private clientSource: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
  private identOptionsSource: BehaviorSubject<IdentOptionsEnum> = new BehaviorSubject<IdentOptionsEnum>(null);
  private isFirstLoading: boolean = false;
  private isFirstLoadingIdentOptions: boolean = false;
  private isMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private statuses: string[] = ['CLIENT', 'CARD', 'APPLICATION', 'LOAN', 'FINISHED_LOAN'];
  private token: string;
  private param: ReplaySubject<{ operationBindingId: number }> = new ReplaySubject<{ operationBindingId: number }>(1);

  constructor(
    private clientService: ClientService,
    private location: Location,
    private cookieService: CookieService,
    public clearStoreService: ClearStoreService,
    private eventHelper: SendEventHelper,
  ) {
    super(clearStoreService);
  }

  get isMenuVisible$(): Observable<boolean> {
    return this.isMenu.asObservable();
  }

  get restructuringParam$(): Observable<{ operationBindingId: number }> {
    return this.param.asObservable();
  }

  public getClientToken(): string {
    return this.token ? this.token : sessionStorage.getItem('jwt_token');
  }

  public getClient(loadAgain?: boolean): Observable<ClientModel> {
    if (loadAgain || (!this.clientSource.value && !this.isFirstLoading)) {
      this.eventHelper.send(sessionStorage.getItem('phone'), 'started getClient method');
      this.loadFirstTime();
    }
    return this.clientSource.asObservable().pipe(filter(isNonNull));
  }

  public getClientIdentOptions(loadAgain?: boolean): Observable<IdentOptionsEnum> {
    if (loadAgain || (!this.identOptionsSource.value && !this.isFirstLoadingIdentOptions)) {
      this.loadIdentOptions();
    }
    return this.identOptionsSource.asObservable().pipe(filter(isNonNull));
  }

  public loadFirstTime(): void {
    this.isFirstLoading = true;
    this.clientService
      .getClientInfo()
      .pipe(
        tap((client: ClientModel) => {
          this.setClient(client);
        }),
        finalize(() => (this.isFirstLoading = false)),
      )
      .subscribe();
  }

  public loadIdentOptions(): void {
    this.isFirstLoadingIdentOptions = true;
    this.clientService
      .getClientIdentOptions()
      .pipe(finalize(() => (this.isFirstLoadingIdentOptions = false)))
      .subscribe((item) => this.setClientIdentOptions(item));
  }

  public setClientIdentOptions(item: IdentOptionsEnum) {
    this.identOptionsSource.next(item);
  }

  public setClient(client: ClientModel): void {
    this.eventHelper.send(sessionStorage.getItem('phone'), 'started setClient method');
    this.clientSource.next(client);
    this.isMenu.next(this.statuses.includes(client.stage));
  }

  public setClientToken(token: string): void {
    this.token = token;
    sessionStorage.setItem('jwt_token', token);
  }

  public updateClient(): Observable<ClientModel> {
    return this.clientService.getClientInfo().pipe(tap((response) => this.setClient(response)));
  }

  public updateClientIdentOptions(): Observable<IdentOptionsEnum> {
    return this.clientService.getClientIdentOptions().pipe(tap((response) => this.setClientIdentOptions(response)));
  }

  public clear(): void {
    this.cookieService.deleteAll('refresh_token', '/');
    this.setClientToken(null);
    this.clientSource.next(null);
    this.identOptionsSource.next(null);
  }

  public setRestructuringParam(param: { operationBindingId: number }): void {
    this.param.next(param);
  }

  public getStorageSubject(): BehaviorSubject<any> {
    this.isMenu.next(false);
    return this.clientSource;
  }
}
