import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsWayCodeModule } from '../../../../shared/components/buttons-way-code/buttons-way-code.module';
import { AuthRepeatComponent } from './auth-repeat.component';

@NgModule({
  declarations: [AuthRepeatComponent],
  imports: [CommonModule, TranslateModule, ButtonsWayCodeModule],
  exports: [AuthRepeatComponent],
})
export class AuthRepeatModule {}
