import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoanScheduleWidgetComponent } from './loan-schedule-widget.component';

@NgModule({
  declarations: [LoanScheduleWidgetComponent],
  imports: [CommonModule, MatRippleModule, TranslateModule],
  exports: [LoanScheduleWidgetComponent],
})
export class LoanScheduleWidgetModule {}
