import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
})
export class ButtonComponent {
  @Input()
  public disabled: boolean = false;

  @Input()
  public label: string = 'Button';

  @Input()
  public classes: string = '';

  @Input()
  public type: 'submit' | 'button' | 'reset' = 'button';
  @Input()
  public color: 'primary' | 'secondary' | 'red' | 'inline' | 'black' | 'pure' | 'limpid' | 'white' | 'pink' | 'penalty' | 'underline' =
    'limpid';
  @Input() public img: {
    url: string;
    height?: string;
    width?: string;
  };
  @Output()
  public readonly clickEvent: EventEmitter<Event> = new EventEmitter<Event>();
  @Input()
  private size: 'large' | 'big' | 'xBig' | 'xBigAdaptive' | 'middle' | 'small' | 'xSmall' | 'w100' = 'middle';

  public get btnClasses(): string {
    return `ui-btn ui-btn-${this.color} ui-btn-${this.size} ${this.classes}`;
  }
}
