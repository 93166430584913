<div class="resend-sms d-flex flex-column align-items-center">
  <div class="resend-sms-body">
    <div *ngIf="showSignMessage" class="resend-sms-content">
      <p class="resend-sms__text text-center">{{ 'RESEND.SIGN_MESSAGE' | translate }}</p>
    </div>
    <div *ngIf="showAuthMessage" class="resend-sms-content">
      <p *ngIf="!timeOut && showPhone" [innerHtml]="'AUTH.SHORT_TEXT' | translate" class="resend-sms__text text-center"></p>
      <p *ngIf="timeOut || !showPhone" class="resend-sms__text text-center">{{ 'CONFIRM.PHONE' | translate }}</p>
    </div>
  </div>
  <div
    [ngClass]="
        showPhone ? 'justify-content-md-between' : 'justify-content-md-center'
      "
    class="resend-sms-footer w-100 text-center d-md-flex"
  >
    <ng-container *ngIf="showPhone">
      <app-button
        (click)="resend(verificationType.PHONE)"
        [label]="'BUTTON.REPEAT_PHONE' | translate"
        classes="fs-15 fw-bold mb-3 mb-md-0"
        color="inline"
        size="large"
        type="button"
      >
      </app-button>
    </ng-container>
    <app-button
      (click)="resend(verificationType.SMS)"
      [label]="'BUTTON.REPEAT_SMS' | translate"
      classes="fs-15 fw-bold"
      color="primary"
      size="large"
      type="button"
    >
    </app-button>
  </div>
</div>
