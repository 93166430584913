import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { EnvironmentType } from '../../models/environment.type';

export class InnValidator implements Validator {
  constructor(private ENV: EnvironmentType) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const inn = control.value.toString().split('');

    if (!Boolean(inn.length) || (this.ENV.production === false && inn[8] === '1' && inn[9] === '0')) {
      return null;
    } else {
      const calculatedCheckDigit =
        ((-1 * +inn[0] + 5 * +inn[1] + 7 * +inn[2] + 9 * +inn[3] + 4 * +inn[4] + 6 * +inn[5] + 10 * +inn[6] + 5 * +inn[7] + 7 * +inn[8]) %
          11) %
        10;
      if (calculatedCheckDigit === +inn[9]) {
        return null;
      } else {
        return { invalidInn: true };
      }
    }
  }
}
