import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import * as Config from '../configs/general-config';
import { EnvironmentType } from '../models/environment.type';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  public domain: string = this.environment.cookieDomain;
  private affiliateArray: Array<string> = Config.StaticDataConfig.affiliate;

  constructor(
    @Inject('environment') private environment: EnvironmentType,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthenticationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
      Object.keys(route.queryParams).forEach((key) => {
        if (this.affiliateArray.includes(key)) {
          this.cookieService.set(key, route.queryParams[key], 30, '/', this.domain);
        }
      });
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}
